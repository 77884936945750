import dayjs from 'dayjs'
import config from '../global-config'
import { groupBy, get, sortBy, isEmpty } from 'lodash'
import { alphabet } from '../component/ChoiceItem'
import { isAdminRole } from './userUtils'
import edjsHTML from 'editorjs-html'
export const formatTotalTime = (time) => {
  // return dayjs
  //   .utc(dayjs.duration(time, 'h').asMilliseconds())
  //   .format('H[h] mm[m]');
  return time + 'h'
}

export const formatMoney = (
  money,
  freeText = false,
  currency = 'SGD',
  minimumFractionDigits = 2
) => {
  if (!money) {
    return '$0.00'
  }
  if (money === 0 && freeText) {
    return 'Free'
  }

  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: minimumFractionDigits,
  }).format(money)
}

export const formatDateToTime = (time) => {
  const now = dayjs()
  const end = dayjs.utc(time)
  const duration = dayjs.duration(now.diff(end))
  const hours = Math.floor(duration.asHours())
  if (hours === 0) {
    const minute = Math.round(duration.asMinutes())
    return minute + ' minutes ago'
  }
  return hours + ' hour ago'
}

export const getImageAttachments = (attachments) => {
  if (Array.isArray(attachments) && attachments.length === 0) {
    return ''
  }
  const image = attachments.filter((item) => item.name === 'image_512')

  return `${config.imageURL}/${get(image, '[0].res_model', '')}/${get(
    image,
    '[0].res_id',
    ''
  )}/${get(image, '[0].res_field', '')}`
}

export const getImageAttachment = (attachment) => {
  if (!attachment) {
    return ''
  }
  return `${config.imageURL}/${get(attachment, 'res_model', '')}/${get(
    attachment,
    'res_id',
    ''
  )}/${get(attachment, 'res_field', '')}`
}

export const getTypeAttachment = (attachment) => {
  if (!attachment && !attachment.mimetype) {
    return ''
  }
  return attachment.mimetype.split('/')[1].toUpperCase()
}

export const fileToBase64 = (fileUploaded) => {
  return new Promise((resolve) => {
    var reader = new FileReader()
    reader.onload = function (event) {
      resolve(event.target.result)
    }

    reader.readAsDataURL(fileUploaded)
  })
}
export const mapListUserAnswerToObj = (arr) => {
  return groupBy(arr, (item) => item?.user?.id)
}

export const mapListOutcomeToDropdown = (arr) => {
  if (!Array.isArray(arr)) {
    return []
  }
  return arr.map((item) => ({ value: item.id, label: item.outcome }))
}

export const mapListZoomsToDropdown = (arr) => {
  if (!Array.isArray(arr)) {
    return []
  }
  return arr.map((item) => ({ value: item.id, label: item.email }))
}

export const isCustomType = (type) => {
  let isCustomType = true
  if (type === 'daily' || type === 'weekday' || type === 'weekends') {
    isCustomType = false
  }
  return isCustomType
}

export const formatDate = (start_date, end_date) => {
  const startDate = dayjs(start_date)
  const endDate = dayjs(end_date)

  if (startDate.isValid() && endDate.isValid()) {
    return `${startDate.format('DD MMM  YYYY')} - ${endDate.format(
      'DD MMM  YYYY'
    )}`
  }
}

export const isEnrollCourse = (partners, course) => {
  let isEnroll = false
  const courseValidity = get(course, 'course_validity', 0)
  if (
    Array.isArray(partners) &&
    partners.length > 0 &&
    partners[0].channel_id === course.id
  ) {
    isEnroll = true

    if (courseValidity !== 0) {
      const now = dayjs()
      const writeDate = get(partners, '[0].write_date', '')
      const expiredDate = dayjs(writeDate).add(courseValidity, 'day')
      const isExpired = now.isAfter(expiredDate)
      isExpired ? (isEnroll = false) : (isEnroll = true)
    }
  }

  return isEnroll
}

const isInt = (n) => {
  return Number(n) === n && n % 1 === 0
}

const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0
}

export const checkInput = (e) => {
  const value = Number(e.currentTarget.value)
  const valid = isInt(value)
  const dot = /\.{1}/
  if (valid || dot.test(value)) {
    return true
  }

  return false
}

export const mapScheduleToDropDown = (
  schedules,
  classCode = false,
  active = false,
  classCodeV2 = false
) => {
  if (!schedules) {
    return []
  }

  if (Array.isArray(schedules) && schedules.length === 0) {
    return []
  }

  const data = schedules
    .filter((item) => {
      const isActive = get(item, 'active')
      if (!active) {
        return item
      }
      if (active && isActive) {
        return item
      }
    })
    .map((schedule) => {
      const id = get(schedule, 'id', '')
      const startDate = get(schedule, 'start_date', '')
      const endDate = get(schedule, 'end_date', '')
      const code = `${
        classCode ? ` / Class Code: ${get(schedule, 'class_code', '')}` : ''
      }`

      const code2 = `${
        classCodeV2 ? `(${get(schedule, 'class_code', '').trim()})` : ''
      }`
      const label = schedule.isAlwaysAvailable
        ? `${code2} Always Available ${code}`
        : `${code2} ${dayjs(startDate).format('DD MMM')} - ${dayjs(
            endDate
          ).format('DD MMM YYYY')} ${code}`
      const obj = {
        value: id,
        label,
        class_code: get(schedule, 'class_code', ''),
      }
      return obj
    })
  return data
}

export const mapSalesToDropDown = (listSales) => {
  if (!listSales) {
    return []
  }

  if (Array.isArray(listSales) && listSales.length === 0) {
    return []
  }

  const data = listSales.map((sales) => {
    const id = get(sales, 'id', '')
    const label = get(sales, 'partner.name', '')

    const obj = { value: id, label }
    return obj
  })
  return data
}

export const getTypesSubmit = (text, url, files) => {
  let types = ''

  if (text && text.length !== 0) {
    types += 'text,'
  }
  if (url && url.length !== 0) {
    types += 'url,'
  }

  if (Array.isArray(files) && files.length !== 0) {
    types += 'attachment,'
  }

  return types
}

export const getAnswerTimeBySlide = (
  slide,
  allAnswerTime,
  wfaDomain = false
) => {
  if (!Array.isArray(allAnswerTime)) {
    return {}
  }
  const slideId = get(slide, 'id', '')

  const result = allAnswerTime.find((item) => item.slide_id === slideId)

  return result
}

export const getIsCompleteQuiz = (allAnswer, questions, answerTimeOfQuiz) => {
  if (!Array.isArray(allAnswer) || !Array.isArray(questions)) {
    return false
  }

  return (
    allAnswer.length >= questions.length &&
    get(answerTimeOfQuiz, 'end_date', '')
  )
}

export const mapListCourseToDropDown = (listCourse) => {
  if (!Array.isArray(listCourse)) {
    return null
  }
  return listCourse.map((item) => ({
    value: item.id,
    label: item.name,
  }))
}

export const mapListClientToDropDown = (data, currentUserClients) => {
  if (!isEmpty(currentUserClients)) {
    return currentUserClients?.map((item) => ({
      value: item?.client?.id,
      label: item?.client?.name,
    }))
  }
  if (!Array.isArray(data)) {
    return null
  }
  return data?.map((item) => ({
    value: item.id,
    label: item.name,
  }))
}

export const getCourseEnrollment = (listCourse) => {
  if (!Array.isArray(listCourse)) {
    return []
  }
  return listCourse.filter(
    (item) => Array.isArray(item.partners) && item.partners.length !== 0
  )
}

export const getListLearningOutCome = (slides) => {
  if (!Array.isArray(slides)) {
    return null
  }

  // step 1: get list question on slides
  const listQuestion = sortBy(
    slides
      .reduce(
        (acc, slide) =>
          !slide.is_category && slide.questions && slide.questions.length !== 0
            ? [...acc, ...slide.questions]
            : acc,
        []
      )
      .filter((item) => item.assessment_criteria),
    'id'
  )

  const result = []

  listQuestion.forEach((item) => {
    if (item.assessment_criteria.criteria) {
      const existAssessment = result.find(
        (data) =>
          data.assessment_criteria.criteria ===
            item.assessment_criteria.criteria &&
          data.assessment_criteria.learning_outcome_id ===
            item.assessment_criteria.learning_outcome_id
      )

      if (!existAssessment) {
        result.push(item)
      }
    }
  })

  // step 2: remove duplicate learning out come id
  return result
}

// export const getTypeLearningOutcome = (type, listLearningOutcome) => {
//   if (!Array.isArray(listLearningOutcome)) {
//     return null
//   }

//   return listLearningOutcome.filter((item) => {
//     if (!item.is_custom) {
//       const outcome = get(item, 'assessment_criteria.learning_outcome', '')
//       return outcome.type === type
//     }
//     return item.type === type
//   })
// }

const TEXT_NEED_REPLACE = [
  '–',
  '“',
  '”',
  '	',
  '	',
  '’',
  /<([a-z]+)(?![^>]*\/>)[^>]*>/g,
  /<\/([a-zA-Z]+)(?=[\s/>])>/g,
  /:&nbsp;/g,
  /&nbsp;/g,
  /•/g,
  /●/g,
  /\s+/g,
  /(?!^)-/g,
]

const TEXT_REPLACE = [
  '-',
  '"',
  '"',
  ' ',
  ' ',
  `'`,
  '',
  '\n',
  '',
  ' ',
  '-',
  '-',
  ' ',
  '\n -',
]
export const replaceTextForExporting = (text = '') => {
  let replaceText = text

  for (let i = 0; i < TEXT_NEED_REPLACE.length; i++) {
    replaceText = replaceText.replace(TEXT_NEED_REPLACE[i], TEXT_REPLACE[i])
  }

  return replaceText.trim().replace(/\u200b/g, '')
}

export const MAPPING_NUMBER = [
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
  'Ten',
]
export const replaceTextForMQC = (answer, mqc, isFeedBack) => {
  const competent = get(answer, 'competent')
  const userAnswer = parseSafe(get(answer, 'value', ''))
  let correctQuestionText = ''
  mqc &&
    mqc.forEach((item) => {
      if (item.is_correct) {
        correctQuestionText += `  ${alphabet[item.order]}: ${item.text}\n`
      }
    })

  let userSelected = ''

  userAnswer &&
    userAnswer.forEach((item) => {
      const option =
        mqc && mqc.find((question) => question.order === item.order)
      if (option) {
        userSelected += `${alphabet[option.order]}: ${option.text}\n`
      }
    })

  if (isFeedBack) {
    return replaceTextForExporting(userSelected)
  }
  if (competent) {
    return `Your answer is correct: \n${replaceTextForExporting(
      correctQuestionText
    )}`
  } else {
    return `Wrong answer selected\n${replaceTextForExporting(
      userSelected
    )}\nCorrect answer:\n${replaceTextForExporting(correctQuestionText)}`
  }
}

export const userType = (gid, groups) => {
  return groups.find((item) => item.gid === gid)
}

export const mapListCourseToSearch = (listCourse, listCourseOfSeller) => {
  return listCourse.reduce((acc, item, i) => {
    const matchCourse = listCourseOfSeller.find(
      (course) => course.channel_id === item.id
    )

    return !matchCourse
      ? [...acc, { key: i, data: item, value: item.name }]
      : acc
  }, [])
}

export const mapListStudentsToSearch = (listStudents, listStudentsOfClient) => {
  return listStudents.reduce((acc, item) => {
    const matchStudent = listStudentsOfClient.find(
      (student) => student.user_id === item.id
    )

    return !matchStudent ? [...acc, item] : acc
  }, [])
}

export const mapListCourseOfSeller = (listCourseOfSeller, listCourses) => {
  if (!Array.isArray(listCourseOfSeller) || !Array.isArray(listCourses)) {
    return []
  }

  const courses = []
  for (let i = 0; i < listCourses.length; i++) {
    const course = listCourseOfSeller.find(
      (item) => item.channel_id === listCourses[i].id
    )

    if (course) {
      courses.push(listCourses[i])
    }
  }
  return courses
}

export const validSchedules = (schedules) => {
  const now = dayjs()
  if (!Array.isArray(schedules)) {
    return []
  }
  return schedules.filter(
    (item) => item.active && now.isSameOrBefore(item.end_date, 'date')
  )
}

export const calculateSeatsLeft = (studentEnroll, maxSizeSchedule) => {
  const availableSeatsSchedule = maxSizeSchedule - studentEnroll
  if (availableSeatsSchedule <= 0) {
    return 0
  }
  return availableSeatsSchedule
}

export const availableSeats = (maxSizeSchedule, seats) => {
  if (!maxSizeSchedule) {
    return true
  }
  return seats ? true : false
}

export const getRawDescription = (data) => {
  if (typeof data === 'string') {
    return data
  }

  const blocks = get(data, 'blocks', [])

  const text = blocks.find((item) => {
    if (item.type === 'paragraph') {
      return item.data.text
    }
  })
  return text ? convertSpecialCharacter(text.data.text) : ''
}

export const convertSpecialCharacter = (data) => {
  return data.replace(/&amp;/g, '&')
}

export const getDescriptionData = (data) => {
  try {
    const parsedData = JSON.parse(data)
    return parsedData
  } catch (error) {
    return data
  }
}

export const isYoutubeUrl = (url) => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:(?:youtu\.be\/)|(?:youtube\.com)\/(?:v\/|u\/\w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/
  return regex.test(url)
}

export const getAttachmentByField = (attachments, field) => {
  if (!Array.isArray(attachments)) {
    return null
  }
  return attachments.find((item) => item.name === field)
}

export const validateNric = (str) => {
  if (str.length != 9) return false

  str = str.toUpperCase()

  var i,
    icArray = []
  for (i = 0; i < 9; i++) {
    icArray[i] = str.charAt(i)
  }

  icArray[1] = parseInt(icArray[1], 10) * 2
  icArray[2] = parseInt(icArray[2], 10) * 7
  icArray[3] = parseInt(icArray[3], 10) * 6
  icArray[4] = parseInt(icArray[4], 10) * 5
  icArray[5] = parseInt(icArray[5], 10) * 4
  icArray[6] = parseInt(icArray[6], 10) * 3
  icArray[7] = parseInt(icArray[7], 10) * 2

  var weight = 0
  for (i = 1; i < 8; i++) {
    weight += icArray[i]
  }

  var offset = icArray[0] == 'T' || icArray[0] == 'G' ? 4 : 0
  var temp = (offset + weight) % 11

  var st = ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A']
  var fg = ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K']

  var theAlpha
  if (icArray[0] == 'S' || icArray[0] == 'T') {
    theAlpha = st[temp]
  } else if (icArray[0] == 'F' || icArray[0] == 'G') {
    theAlpha = fg[temp]
  }

  return icArray[8] === theAlpha
}

export const isScheduleAlwaysAvailable = (startDate, endDate) => {
  return dayjs(endDate).diff(dayjs(startDate), 'years') === 10
}

export const encodedSlideByQuiz = (slides) => {
  let string = ''
  slides.forEach((item) => {
    if (item.questions.length > 0) {
      string += `&slide_ids=${item.id}`
    }
  })
  return string
}

export const renderColValue = (editValue, value, isEditOnMobile) => {
  return isEditOnMobile ? editValue : value
}

export const parseOptionsPrice = (optionsPrice) => {
  if (!optionsPrice) {
    return []
  }
  return JSON.parse(optionsPrice)
}

export const getPaymentMethodByCourse = (array, courseId) => {
  if (!Array.isArray(array)) {
    return []
  }
  return array.filter(
    (item) =>
      item.payment_method === 'credit' &&
      item.channel_id === courseId &&
      item.value
  )
}

export const mapListCategoryToDropDown = (categories) => {
  if (!Array.isArray(categories)) {
    return []
  }
  return categories.map((cat) => ({ value: cat.id, label: cat.name }))
}

export const getOptionPrice = (prices, mode = 'lowest', obj = false) => {
  if (!Array.isArray(prices)) {
    return 0
  }
  if (Array.isArray(prices) && prices.length === 0) {
    return 0
  }
  const price = prices.reduce((acc, curr) => {
    if (mode === 'lowest') {
      return parseFloat(acc.price) < parseFloat(curr.price) ? acc : curr
    } else {
      return parseFloat(acc.price) > parseFloat(curr.price) ? acc : curr
    }
  })

  return obj ? price : parseFloat(get(price, 'price', 0))
}
export const mapListLearningOutcomeUser = (listLearningOutcome, list) => {
  if (!Array.isArray(listLearningOutcome) || !Array.isArray(list)) {
    return []
  }
  const arr = []
  for (let i = 0; i < list.length; i++) {
    const outcomeUser = listLearningOutcome.find(
      (item) => item.id === list[i].learning_outcome_id
    )

    if (outcomeUser) {
      arr.push(list[i])
    }
  }
  return arr
}

export const parseSafe = (string) => {
  try {
    const data = JSON.parse(string)

    return data ? data : null
  } catch (error) {
    return string
  }
}

export const parseEditorSafe = (string) => {
  try {
    const edjsParser = edjsHTML()
    const data = edjsParser.parse(string)

    return data ? data : null
  } catch (error) {
    return string
  }
}

export const sortCourses = (schedules, prop) => {
  if (isEmpty(schedules)) {
    return []
  }
  return schedules.sort((a, b) => {
    return dayjs(b[prop]).diff(a[prop])
  })
}

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const format = (amount) => {
  if (!amount) {
    return 0
  }
  const surplus = (parseFloat(amount) * 100) % 100
  if (surplus === 0) {
    return amount + ''
  }
  return Number(amount).toFixed(2)
}

export const msToTime = (duration) => {
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return hours + ':' + minutes + ':' + seconds
}

export const getFileSize = (size) => {
  let i = Math.floor(Math.log(size) / Math.log(1024))
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  )
}

export const getResponseData = (response) => {
  return response?.data?.data
}

export const isAdminClient = (currentUser) => {
  const currentUserClients = get(currentUser, 'user_clients', [])
  const currentUserGroups = get(currentUser, 'user_groups', [])
  return isAdminRole(currentUserGroups) && currentUserClients?.length > 0
}

export async function downloadImage(imageSrc, nameOfDownload = 'my-image.png') {
  const response = await fetch(imageSrc)

  const blobImage = await response.blob()

  const href = URL.createObjectURL(blobImage)

  const anchorElement = document.createElement('a')
  anchorElement.href = href
  anchorElement.download = nameOfDownload

  document.body.appendChild(anchorElement)
  anchorElement.click()

  document.body.removeChild(anchorElement)
  window.URL.revokeObjectURL(href)
}

export const isMainAdmin = (currentUser) => {
  const currentUserClients = get(currentUser, 'user_clients', [])
  const currentUserGroups = get(currentUser, 'user_groups', [])
  return isAdminRole(currentUserGroups) && currentUserClients?.length === 0
}

export const isLoginAsRoleAdmin = (currentUser) => {
  const currentUserGroups = get(currentUser, 'user_groups', [])
  return !!isAdminRole(currentUserGroups)
}

export const isValidUrl = (str) => {
  const lc = str.toLowerCase().trim()
  return lc.slice(0, 8) === 'https://' || lc.slice(0, 7) === 'http://'
}

export const defaultStringifyOption = {
  skipNulls: true,
  encode: false,
  indices: false,
}
