import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { get, sortBy, uniq } from 'lodash'
import { BackTop, Switch } from 'antd'
import dayjs from 'dayjs'
import classNames from 'classnames'
import initialScorm from 'component/ChannelDetails/scorm'
import * as utilHelpers from 'utils/helper'
import * as selectors from 'container/Home/selectors'
import SideBar from '../SideBar'
import AboutLesson from './AboutLesson'
import QuizLesson from './QuizLesson'
import Sections from '../Sections'
import images from 'Themes/Images'
import { SLIDE_TYPE } from 'utils/constants'
import { createLesson, createQuiz, setQuestions } from '../../../../actions'
import { loadSlideDetails } from 'container/Home/actions'
import { useFocusHooks } from 'utils/hooks'
import { ERROR_MESSAGE } from 'utils/constants'
import {
  showDeleteLessonModal,
  showReorderQuizModal,
} from 'container/Modal/actions'
import { makeSelectQuestions, makeSelectSchedules } from '../../../../selectors'
import { parse } from 'query-string'
import './styles.scss'
import { useMediaQuery } from 'react-responsive'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import { uploadImage } from 'src/container/CMS/hooks'
import { makeSelectDomain } from 'container/Home/selectors'
import { DOMAIN } from 'src/routes'
import useGetTrainer from '../../../../../../hooks/useGetTrainer'
import config from 'src/global-config'
import { parseEditorSafe, parseSafe } from '../../../../../../utils/helper'
import { makeSelectCurrentUser } from '../../../../../Auth/selectos'

const LessonSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
})

const lessonTypeData = [
  { id: SLIDE_TYPE.INFOGRAPHIC, name: 'Images' },
  { id: SLIDE_TYPE.VIDEO, name: 'Video' },
  { id: SLIDE_TYPE.PRESENTATION, name: 'Document (PDF, PPT, PPTX)' },
  { id: SLIDE_TYPE.STREAM, name: 'VidTags link' },
  { id: SLIDE_TYPE.SCORM, name: 'Scorm' },
]

const Lessons = ({ course }) => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 780 })
  const { isTrainer } = useGetTrainer()
  const { search } = useLocation()
  const { quiz } = parse(search)
  const { pathname } = useLocation()
  const { id } = useParams()
  const domain = useSelector(makeSelectDomain())

  const ascendoDomain =
    get(domain, 'domain', '') === DOMAIN.ASCENDO ||
    get(domain, 'domain', '') === DOMAIN.ASCENDO_2

  const wfaDomain = get(domain, 'domain', '') === DOMAIN.WFA

  const lessonType = useMemo(() => {
    if (!ascendoDomain && !wfaDomain) {
      return lessonTypeData.filter((type) => type.id !== SLIDE_TYPE.SCORM)
    } else {
      return lessonTypeData
    }
  }, [lessonTypeData, ascendoDomain])

  const saveBtn = useRef(null)
  const slides = useSelector(selectors.makeSelectSlides())
  const selectedSlide = useSelector(selectors.makeSelectSelectedSlide())
  const slideDetails = useSelector(selectors.makeSelectSlideDetails())
  const schedules = useSelector(makeSelectSchedules())

  const dispatch = useDispatch()
  const [listSchedules, setListSchedules] = useState([])
  const [radio, setRadio] = useState(SLIDE_TYPE.INFOGRAPHIC)
  const [addSection, setAddSection] = useState(false)
  const [publish, setPublish] = useState(false)
  const [pdf, setPdf] = useState('')
  const [imgUrl, setImgUrl] = useState('')
  const [videoUrl, setVideoUrl] = useState('')
  const [scormFileUrl, setScormFileUrl] = useState('')
  const [scormVersion, setScormVersion] = useState('')

  const [editTitle, setEditTitle] = useState(false)
  const [listImages, setListImages] = useState([])
  const [currentPage, setCurrentPage] = useState(2)
  const bottomRef = useRef(null)
  const [isTabQuizAndAssessment, setIsTabQuizAndAssessment] = useState(!!quiz)

  const executeScroll = () =>
    bottomRef.current.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    })

  const questions = useSelector(makeSelectQuestions())
  const requiredSchedule = get(course, 'is_schedule', false)
  const isScormSlide = get(slideDetails, 'slide_type', '') === SLIDE_TYPE.SCORM
  const scormFile = get(slideDetails, 'filename', '')

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      schedule: '',
      stream: '',
      quiz_pass_rate: '',
      show_quiz_only: false,
    },
    validationSchema: LessonSchema,
    onSubmit: async (values) => {
      if (radio === SLIDE_TYPE.VIDEO && !videoUrl) {
        return formik.setFieldError('videoUrl', 'This field is required')
      }

      let uploadImages = ''
      if (listImages && listImages.length > 0) {
        dispatch(showGlobalLoading())
        const requests = []
        for (let i = 0; i < listImages.length; i++) {
          if (get(listImages, `[${i}].file`)) {
            requests.push(uploadImage(get(listImages, `[${i}].file`)))
          } else if (get(listImages, `[${i}].name`)) {
            requests.push(get(listImages, `[${i}]`))
          }
        }
        const data = await Promise.all(requests)

        uploadImages =
          Array.isArray(data) &&
          data.map((item, i) =>
            item.size
              ? {
                  ...item,
                }
              : {
                  data_url: item.url,
                  name: item.name,
                  size: get(listImages, `[${i}].file.size`, ''),
                }
          )
      }

      let params = {
        name: values.name,
        slide_type: radio,
        is_category: false,
        description: values.description,
        is_published: publish,
        schedules: listSchedules,
        show_quiz_only: values.show_quiz_only,
      }

      if (radio === SLIDE_TYPE.INFOGRAPHIC) {
        params = {
          ...params,
          image_url: JSON.stringify(uploadImages),
        }
      } else if (radio === SLIDE_TYPE.STREAM) {
        params = {
          ...params,
          url: values.stream,
        }
      } else if (radio === SLIDE_TYPE.VIDEO) {
        params = {
          ...params,
          url: videoUrl,
        }
      } else if (radio === SLIDE_TYPE.PRESENTATION && pdf) {
        params = {
          ...params,
          document_url: pdf.url,
        }
      } else if (radio === SLIDE_TYPE.PRESENTATION && !pdf) {
        params = {
          ...params,
          slide_type: 'infographic',
          document_url: '""',
        }
      } else if (radio === SLIDE_TYPE.SCORM) {
        params = {
          ...params,
          filename: scormFileUrl || null,
          scorm_version: scormVersion,
        }
      }

      if (selectedSlide) {
        params = {
          ...params,
          id: get(selectedSlide, 'id'),
          sequence: get(selectedSlide, 'sequence'),
        }
      }

      dispatch(
        createLesson(
          selectedSlide ? { ...selectedSlide, ...params } : { ...params },
          id,
          resetForm
        )
      )
    },
  })

  const resetForm = () => {
    formik.resetForm()
    formik.resetForm('description', [])
    setListImages([])
    setImgUrl('')
    setVideoUrl('')
    setRadio(SLIDE_TYPE.INFOGRAPHIC)
    dispatch(setQuestions([]))
  }

  const { currentRef, inputRef, isActive, setIsActive } = useFocusHooks(
    false,
    resetForm
  )

  useEffect(() => {
    if (selectedSlide) {
      dispatch(loadSlideDetails({ id: get(selectedSlide, 'id') }))
    }
  }, [selectedSlide])

  useEffect(() => {
    if (isScormSlide && scormFile && scormVersion)
      initialScorm(scormVersion, true)
  }, [scormVersion, scormFile, isScormSlide])

  useEffect(() => {
    if (slideDetails) {
      formik.setFieldValue('description', slideDetails.description)
    }
  }, [slideDetails])

  useEffect(() => {
    if (slideDetails && !quiz) {
      const slide_type = get(slideDetails, 'slide_type', '')
      const slideType = get(slideDetails, 'slide_type', '')
      const attachMents = get(slideDetails, 'attachments', [])
      const is_published = get(slideDetails, 'is_published', false)
      const schedule_id = get(slideDetails, 'schedule_id')
      const listSchedule = get(selectedSlide, 'schedules', [])

      const scormFileName = get(selectedSlide, 'filename', '')
      const scormVersion = get(selectedSlide, 'scorm_version', '')
      setScormFileUrl(scormFileName)
      setScormVersion(scormVersion)
      setRadio(slide_type)
      setPublish(is_published)
      setListSchedules(listSchedule)
      formik.setFieldValue('name', slideDetails.name)

      if (Array.isArray(schedules)) {
        const schedule = schedules.find((item) => item.id === schedule_id)
        if (schedule) {
          const id = get(schedule, 'id', '')
          const startDate = get(schedule, 'start_date', '')
          const endDate = get(schedule, 'end_date', '')
          const label = `${dayjs(startDate).format('DD MMM')} - ${dayjs(
            endDate
          ).format('DD MMM YYYY')}`
          formik.setFieldValue('schedule', { value: id, label })
        } else {
          formik.setFieldValue('schedule', '')
        }
      }

      if (slideType === SLIDE_TYPE.INFOGRAPHIC) {
        const listImg = get(slideDetails, 'image_url', '')
        setListImages(utilHelpers.parseSafe(listImg))
        setVideoUrl('')
      } else if (slideType === SLIDE_TYPE.STREAM) {
        const url = get(slideDetails, 'url', '')
        formik.setFieldValue('stream', url)
      } else if (slideType === SLIDE_TYPE.VIDEO) {
        const url = get(slideDetails, 'url', '')
        setVideoUrl(url)
        setImgUrl('')
      } else if (slideType === SLIDE_TYPE.PRESENTATION) {
        if (get(slideDetails, 'document_url', '')) {
          const url = get(slideDetails, 'document_url', '')
          const splitString = url.split('/')
          const fileName = get(splitString, `[${splitString.length - 1}]`, '')
          if (fileName && url) {
            setPdf({
              url: url,
              name: fileName,
            })
          } else {
            setPdf('')
          }
        } else {
          setPdf('')
        }
        setImgUrl('')
        setVideoUrl('')
      }
    }
  }, [slideDetails, quiz])

  const handleQuiz = useCallback(
    (isQuiz) => {
      if (isQuiz) {
        formik.setFieldValue('name', get(slideDetails, 'name', ''))
        const listQuestions = get(slideDetails, 'questions', [])
        const quizPassRate = get(selectedSlide, 'quiz_pass_rate')
        const is_published = get(slideDetails, 'is_published', false)
        formik.setFieldValue('quiz_pass_rate', quizPassRate)
        formik.setFieldValue(
          'show_quiz_only',
          get(slideDetails, 'show_quiz_only', false)
        )
        formik.setFieldValue('is_show_timer', get(slideDetails, 'timer', false))
        formik.setFieldValue('timer_hours', get(slideDetails, 'timer.hours', 0))
        formik.setFieldValue(
          'timer_minutes',
          get(slideDetails, 'timer.minutes', 0)
        )
        formik.setFieldValue(
          'timer_seconds',
          get(slideDetails, 'timer.seconds', 0)
        )
        formik.setFieldValue(
          'is_show_answer',
          get(slideDetails, 'is_show_answer', false)
        )
        formik.setFieldValue(
          'is_correct_answer',
          get(slideDetails, 'is_correct_answer', false)
        )
        setPublish(is_published)
        dispatch(
          setQuestions(
            (listQuestions.length !== 0 &&
              sortBy(listQuestions, ['sequence'])) ||
              []
          )
        )
      }
    },
    [slideDetails]
  )

  useEffect(() => {
    if (slideDetails) {
      const { quiz } = parse(search)
      handleQuiz(isTabQuizAndAssessment === true || !!quiz)
    }
  }, [slideDetails, isTabQuizAndAssessment])

  const handleSubmit = () => {
    setEditTitle(false)
    if (!quiz) {
      formik.handleSubmit()
    } else {
      handleQuizSubmit()
    }
  }

  const handleQuizSubmit = () => {
    const cloneAllQuestion = [...questions]
    let error = false
    for (let i = 0; i < cloneAllQuestion.length; i++) {
      if (
        !cloneAllQuestion[i].question ||
        (!cloneAllQuestion[i].types && !cloneAllQuestion[i].multiple_choices)
      ) {
        cloneAllQuestion[i].error = ERROR_MESSAGE
        error = true
      } else {
        cloneAllQuestion[i].error = ''
        error = false
      }
    }
    if (error) {
      return dispatch(setQuestions(cloneAllQuestion))
    }

    const timer = {
      hours: formik.values.timer_hours || 0,
      minutes: formik.values.timer_minutes || 0,
      seconds: formik.values.timer_seconds || 0,
    }
    const isShowTimer = formik.values.is_show_timer || false
    const dataTimer = isShowTimer && timer ? timer : null

    dispatch(
      createQuiz({
        questions: questions,
        idCourse: id,
        name: formik.values.name,
        quizPassRate: formik.values.quiz_pass_rate,
        show_quiz_only: formik.values.show_quiz_only,
        is_show_answer: formik.values.is_show_answer,
        is_correct_answer: formik.values.is_correct_answer,
        timer: dataTimer,
      })
    )
  }

  const handleDeleteLesson = () => {
    if (get(selectedSlide, 'id', null)) {
      dispatch(showDeleteLessonModal(selectedSlide, resetForm))
    }
  }

  const handleSetLessonPublish = () => {
    setPublish(!publish)
    formik.submitForm()
  }

  const handleOpenReorderQuizModal = () => {
    dispatch(showReorderQuizModal())
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setEditTitle(false)
      formik.submitForm()
    }
  }

  const styleButton = (left) => ({
    left,
    backgroundColor: '#042580',
    padding: 8,
    borderRadius: 4,
    color: '#fff',
    position: 'absolute',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    top: -8,
    fontSize: 12,
    cursor: 'pointer',
  })

  const handleScrollToBottom = () => {
    dispatch(showGlobalLoading())
    setTimeout(() => {
      setCurrentPage(questions?.length)
      setTimeout(() => {
        dispatch(hideGlobalLoading())
        executeScroll()
      }, 200)
    }, 0)
  }

  const getAllCourses = async () => {
    const requestURL = `${config.baseUrl}/api/lms/courses/channels`
    try {
      const res = await fetch(requestURL, {
        method: 'GET',
      })
      const data = await res.json()
      if (data?.data) {
        return data?.data
      }
    } catch (err) {
      return null
    }
  }

  const getSlidesByCourseID = async (courseID) => {
    // ?order_by=sequence%20asc&order_by=id%20asc&per_page=1000&ignore_per_page=true&published=all
    const requestURL = `${config.baseUrl}/api/lms/courses/channels/${courseID}/slides?order_by=sequence%20asc&order_by=id%20asc&per_page=1000&ignore_per_page=true&published=all`
    try {
      const res = await fetch(requestURL, {
        method: 'GET',
      })
      const data = await res.json()
      if (data?.data) {
        return data?.data
      }
    } catch (err) {
      return null
    }
  }

  const handleMigrate = async () => {
    console.log('here')
    const courses = await getAllCourses()
    const requests = []
    for (let i = 0; i < courses?.length; i++) {
      course = courses[i]
      requests.push(getSlidesByCourseID(course.id))
    }
    const slides = await Promise.all(requests)

    let updated = 0
    const requestsUpdate = []
    for (let i = 0; i < slides?.length; i++) {
      course = courses[i]
      for (let j = 0; j < slides?.[i]?.length; j++) {
        const slide = slides[i][j]
        if (!slide?.is_category) {
          updated += 1
          requestsUpdate.push(updateSlide(slide, course))
        }
      }
    }
    console.log('slides', slides)
    console.log('updated', updated)
    console.log('courses', courses)
  }

  const updateSlide = async (slide, course) => {
    console.log('begin')

    console.log('slide?.description', slide?.description)
    const description = parseSafe(slide?.description)
    console.log('description', description)
    let html = parseEditorSafe(description)
    // console.log(html)
    const htmlDescription = Array.isArray(html) ? uniq(html)?.join(' ') : html
    console.log('htmlDescription', htmlDescription)
    let params = {
      ...slide,
      description: htmlDescription,
    }

    console.log('params', params)
    dispatch(createLesson(params, course?.id, resetForm))
  }

  const currentUser = useSelector(makeSelectCurrentUser())

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="lessons">
        <div className="lessons__left">
          <SideBar
            setListSchedules={setListSchedules}
            currentRef={currentRef}
            isActive={isActive}
            setIsActive={setIsActive}
            slides={slides}
            selectedSlide={selectedSlide}
            resetForm={resetForm}
            addSection={addSection}
            setAddSection={setAddSection}
            formik={formik}
            setListImages={setListImages}
            saveBtnRef={saveBtn}
          />
        </div>

        <div className="lessons__right">
          {/* lesson right top */}
          {addSection ? (
            <Sections setAddSection={setAddSection} />
          ) : (
            <div
              className={classNames('hidden', {
                show: slides && slides.length > 0,
              })}
            >
              <div className="lessons__right__top">
                <div className="inner_container_2">
                  {currentUser && currentUser?.uid === 2 && (
                    <div style={{ cursor: 'pointer' }} onClick={handleMigrate}>
                      Migrate Data
                    </div>
                  )}

                  <div
                    className="add-course__head"
                    style={{
                      display: isMobile ? 'block' : 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: 8,
                    }}
                  >
                    <div>Lesson / Quiz Title</div>
                    <div>
                      <Switch
                        checkedChildren="Published"
                        unCheckedChildren="Unpublished"
                        checked={publish}
                        onChange={handleSetLessonPublish}
                        style={{ marginRight: 12 }}
                        disabled={isTrainer}
                      />
                      {!isTrainer && (
                        <>
                          <button
                            className="btn btn-primary admin-btn-primary"
                            type="button"
                            onClick={handleSubmit}
                            style={{ marginRight: 6 }}
                          >
                            <span className="btn__text">
                              <img src={images.admin.save_icon} alt="" /> Save
                            </span>
                          </button>
                          <button
                            className="btn btn-primary admin-btn-primary"
                            type="button"
                            onClick={handleDeleteLesson}
                          >
                            <span className="btn__text">
                              <img src={images.admin.trash_icon} alt="" />{' '}
                              Delete
                            </span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="channel__title">
                    {!editTitle && selectedSlide ? (
                      <div
                        className="admin__channel__course-name"
                        onClick={() => setEditTitle(true)}
                      >
                        {selectedSlide.name}
                        <img src={images.admin.pen_alt_active} alt="" />
                      </div>
                    ) : (
                      <>
                        <input
                          id="name"
                          name="name"
                          placeholder="Lesson / Quiz Title"
                          type="text"
                          autoComplete="off"
                          className={`add-course__input ${
                            formik.touched.name &&
                            formik.errors.name &&
                            'form__input__error'
                          }`}
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onKeyPress={handleKeyPress}
                          ref={inputRef}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className="form__error">
                            {formik.errors.name}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="lessons__switch">
                    <div
                      className={`lessons__switch-text ${
                        !quiz && 'lessons__switch-text-active'
                      }`}
                      onClick={() => {
                        setIsTabQuizAndAssessment(false)
                        history.push(`${pathname}?route=lessons`)
                      }}
                    >
                      Lesson
                    </div>
                    {slideDetails && (
                      <div style={{ display: 'flex', position: 'relative' }}>
                        <div
                          className={`lessons__switch-text ${
                            quiz && 'lessons__switch-text-active'
                          }`}
                          onClick={() => {
                            history.push(`${pathname}?route=lessons&quiz=true`)
                            setIsTabQuizAndAssessment(true)
                          }}
                        >
                          Quiz & Assessments
                        </div>
                        {quiz &&
                          Array.isArray(questions) &&
                          questions.length > 0 && (
                            <>
                              <span
                                style={styleButton('100%')}
                                onClick={handleOpenReorderQuizModal}
                              >
                                Reorder Quiz
                              </span>
                              <span
                                style={styleButton('170%')}
                                onClick={handleScrollToBottom}
                              >
                                Add Question
                              </span>

                              <span
                                style={styleButton('240%')}
                                onClick={handleScrollToBottom}
                              >
                                Quiz Settings
                              </span>
                            </>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* end lesson right top */}
              <div className="lessons__right__bottom">
                {!quiz ? (
                  <AboutLesson
                    listSchedules={listSchedules}
                    setListSchedules={setListSchedules}
                    schedules={schedules}
                    selectedSlide={selectedSlide}
                    formik={formik}
                    radio={radio}
                    setRadio={setRadio}
                    lessonType={lessonType}
                    videoUrl={videoUrl}
                    setVideoUrl={setVideoUrl}
                    imgUrl={imgUrl}
                    setImgUrl={setImgUrl}
                    pdf={pdf}
                    setPdf={setPdf}
                    listImages={listImages}
                    setListImages={setListImages}
                    requiredSchedule={requiredSchedule}
                    setScormFileUrl={setScormFileUrl}
                    scormFileUrl={scormFileUrl}
                    setScormVersion={setScormVersion}
                    scormVersion={scormVersion}
                    ascendoDomain={ascendoDomain}
                    wfaDomain={wfaDomain}
                    slideDetails={slideDetails}
                  />
                ) : (
                  <QuizLesson
                    dispatch={dispatch}
                    slideDetails={slideDetails}
                    handleQuizSubmit={handleQuizSubmit}
                    questions={questions}
                    idCourse={id}
                    formik={formik}
                    handleSubmit={handleSubmit}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    bottomRef={bottomRef}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <BackTop style={{ right: 120 }}>
        <div className="back-to-top">Top</div>
      </BackTop>
    </form>
  )
}

export default Lessons
