import React, { useRef, useEffect } from 'react'
import { get, isEmpty, orderBy } from 'lodash'
import { Radio } from 'antd'
import ReactPlayer from 'react-player'
import { uploadPdf } from 'src/container/CMS/hooks.js'

import images from 'Themes/Images'
import ScheduleItem from '../../ScheduleItem'
import FileComponent from '../../FileComponent'
import { showError } from 'utils/notification'
import { SLIDE_TYPE } from 'utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { createAttachment, loadAttachment } from '../../../../../actions'
import { makeSelectAttachments } from '../../../../../selectors'
import UploadFileVideo from 'component/UploadFileVideo'
import UploadScormFile from 'component/UploadScormFile'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import ImageUploading from 'react-images-uploading'
import { getFileSize } from 'utils/helper'
import { fileToBase64 } from 'utils/helper'
import './styles.scss'
import { SUPPORTED_FORMATS_DOCUMENT_LESSON } from 'utils/constants'
import useGetTrainer from '../../../../../../../hooks/useGetTrainer'
import TextEditor from '../../../../../../../customs/ClassCompare/src/components/Editor'
import { CKEditorWrapper } from '../../../../../../CMS/screens/Article'

const AboutLesson = ({
  selectedSlide,
  formik,
  radio,
  setRadio,
  lessonType,
  videoUrl,
  setVideoUrl,
  imgUrl,
  setImgUrl,
  pdf,
  setPdf,
  schedules,
  listSchedules,
  setListSchedules,
  requiredSchedule,
  listImages,
  setListImages,
  setScormFileUrl,
  scormFileUrl,
  scormVersion,
  setScormVersion,
  ascendoDomain,
  wfaDomain,
  slideDetails,
}) => {
  const refFile = useRef(null)
  const hiddenFileDoc = useRef(null)
  const dispatch = useDispatch()
  const attachments = useSelector(makeSelectAttachments())
  const { isTrainer } = useGetTrainer()
  const ids =
    listSchedules &&
    new Set(listSchedules.map(({ schedule_id }) => schedule_id))
  const newSchedules = schedules && schedules.filter(({ id }) => !ids.has(id))

  const maxNumber = 69

  const onChange = async (imageList, addUpdateIndex) => {
    await setListImages(imageList)
    formik.submitForm()
  }

  const handleClickFile = () => {
    if (refFile && refFile.current) {
      refFile.current.click()
    }
  }

  useEffect(() => {
    if (selectedSlide) {
      dispatch(loadAttachment())
    }
  }, [selectedSlide])

  const handleClickFileDoc = () => {
    if (hiddenFileDoc.current) {
      hiddenFileDoc.current.click()
    }
  }
  const handleChangeFileDoc = async (event) => {
    dispatch(showGlobalLoading())
    const fileUploaded = event.target.files[0]
    if (
      !SUPPORTED_FORMATS_DOCUMENT_LESSON.includes(get(fileUploaded, 'type', ''))
    ) {
      dispatch(hideGlobalLoading())
      return formik.setFieldError(
        'presentation',
        'Please input the correct type, we just supported this type: PDF, PPT, PPTX'
      )
    }

    const pdf = await uploadPdf(fileUploaded)

    if (pdf) {
      setPdf(pdf)
    }
    dispatch(hideGlobalLoading())
  }

  const handleChangeFile = (event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      const name = get(fileUploaded, 'name', '')

      fileToBase64(fileUploaded).then((result) => {
        if (result) {
          dispatch(
            createAttachment({
              name,
              attachment: result.split('base64,')[1],
            })
          )
        }
      })
    }
  }

  const handleAddNewSchedule = () => {
    if (
      Array.isArray(listSchedules) &&
      Array.isArray(schedules) &&
      listSchedules.length === schedules.length
    ) {
      return showError('Please add a new schedule in the schedule page')
    }
    const emptySchedule = listSchedules.find((item) => !item.schedule_id)

    if (emptySchedule) {
      return showError('Please fill the empty schedule first!')
    }

    setListSchedules([...listSchedules, {}])
  }

  const renderAdditionalFile = () => {
    return (
      attachments &&
      orderBy(attachments, 'id', 'desc').map((item) => {
        return <FileComponent key={item.id} item={item} />
      })
    )
  }

  const renderByRadioButton = () => {
    let content = null

    switch (radio) {
      case SLIDE_TYPE.INFOGRAPHIC:
        content = (
          <div className="add-course__upload-wrap">
            <ImageUploading
              multiple
              value={listImages}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                <div className="upload__image-wrapper">
                  <div
                    className="upload__image-drag-select"
                    style={isDragging ? { background: '#e7ebf4' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <img src={images.upload} width="60" />
                    <div className="upload__image-title">
                      Drag and drop Images Here To Upload
                    </div>
                    <div className="upload__image-connect">Or</div>
                    <div className="upload__image-button">
                      Select Images to Upload
                    </div>
                  </div>

                  <span
                    className="upload__image-button"
                    onClick={onImageRemoveAll}
                    style={{ margin: '20px 0', display: 'inline-block' }}
                  >
                    Remove all images
                  </span>
                  <div className="upload__image-list-wrapper">
                    {imageList.map((image, index) => (
                      <div
                        className="upload__image-image-item-wrapper"
                        key={index}
                      >
                        <div className="upload__image-image-item-heading">
                          <div className="upload__image-image-item-title">
                            {get(image, 'file.name', '') ||
                              get(image, 'name', '')}
                          </div>
                          <div className="upload__image-image-item-size">
                            {getFileSize(
                              get(image, 'file.size', '') ||
                                get(image, 'size', '')
                            )}
                          </div>
                        </div>
                        <div
                          key={index}
                          className="upload__image-image-item"
                          onClick={() => onImageUpdate(index)}
                        >
                          <img src={image['data_url']} />
                        </div>
                        <div className="image-item__btn-wrapper">
                          <div
                            onClick={async () => onImageRemove(index)}
                            className="upload__image-image-item-delete"
                          >
                            <img
                              src={images.admin.trash_icon}
                              className=""
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </ImageUploading>
          </div>
        )
        break
      case SLIDE_TYPE.VIDEO:
        content = (
          <>
            {videoUrl && (
              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player"
                  url={videoUrl}
                  width="95%"
                  height="100%"
                  controls={true}
                />
              </div>
            )}
            <div className="add-lesson">
              <div className="add-lesson__upload" onClick={handleClickFileDoc}>
                <input
                  type="text"
                  name="vimeo"
                  className="add-lesson__input__link"
                  placeholder="Enter Youtube, Vimeo video link."
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.currentTarget.value)}
                />
              </div>
            </div>

            {formik.errors.videoUrl && (
              <div className="form__error">{formik.errors.videoUrl}</div>
            )}
            <div style={{ marginTop: 15 }}>
              <UploadFileVideo setVideoUrl={setVideoUrl} />
            </div>
          </>
        )
        break
      case SLIDE_TYPE.STREAM:
        content = (
          <>
            <div className="add-lesson">
              <div className="add-lesson__upload" onClick={handleClickFileDoc}>
                <input
                  type="text"
                  name="stream"
                  className="add-lesson__input__link"
                  placeholder="Enter VidTags link"
                  value={formik.values.stream}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            {formik.errors.stream && (
              <div className="form__error">{formik.errors.stream}</div>
            )}
          </>
        )
        break
      case SLIDE_TYPE.PRESENTATION:
        content = (
          <>
            <div className="add-lesson">
              <div className="add-lesson__upload" onClick={handleClickFileDoc}>
                <img
                  src={
                    pdf
                      ? images.admin.document_upload_active
                      : images.admin.document_upload
                  }
                  alt=""
                />
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={hiddenFileDoc}
                  onChange={handleChangeFileDoc}
                />
                <div className="add-lesson__text">
                  {pdf ? pdf.name : 'No file uploaded'}
                </div>
              </div>
              <div className="add-lesson__button" style={{ display: 'flex' }}>
                <button
                  className="tertiary-btn"
                  type="button"
                  onClick={() => hiddenFileDoc.current.click()}
                >
                  Browse
                </button>
                <img
                  src={images.admin.trash_icon}
                  style={{ marginLeft: 10, cursor: 'pointer' }}
                  onClick={() => setPdf(null)}
                  alt="remove"
                />
              </div>
            </div>
            {formik.errors.presentation && (
              <div className="form__error">{formik.errors.presentation}</div>
            )}
          </>
        )
        break
      case SLIDE_TYPE.SCORM:
        content = (
          <>
            {(ascendoDomain || wfaDomain) && (
              <div style={{ marginTop: 15 }}>
                <UploadScormFile
                  setScormFileUrl={setScormFileUrl}
                  scormFileUrl={scormFileUrl}
                  scormVersion={scormVersion}
                  setScormVersion={setScormVersion}
                />
              </div>
            )}
          </>
        )
        break
      default:
        break
    }
    return content
  }

  return (
    <div className="about-lesson">
      {isEmpty(selectedSlide) ? <div className="about-lesson-overlay" /> : null}
      <div className="inner_container_2">
        <div className="add-course__description lessons__description">
          <div className="add-course__head" style={{ marginTop: 0 }}>
            DESCRIPTION
          </div>

          <CKEditorWrapper zIndex={100} top={77}>
            {formik.values.description || formik.values.description === '' ? (
              <TextEditor
                value={formik.values.description}
                onChange={(data) => formik.setFieldValue('description', data)}
              />
            ) : null}
          </CKEditorWrapper>

          {formik.touched.description && formik.errors.description && (
            <div className="form__error">{formik.errors.description}</div>
          )}
        </div>
        <div className="add-course__head">Content Upload</div>
        <div className={`lessons__types ${isTrainer ? 'disabledbutton' : ''}`}>
          <Radio.Group
            onChange={(e) => setRadio(e.target.value)}
            value={radio}
            name="add-course__radio-type"
          >
            {lessonType &&
              lessonType.map((type) => (
                <Radio key={type.id} value={type.id}>
                  {type.name}
                </Radio>
              ))}
          </Radio.Group>
        </div>
        <div className={`${isTrainer ? 'disabledbutton' : ''}`}>
          {renderByRadioButton()}
        </div>
        {selectedSlide && requiredSchedule && (
          <div className="lessons__tags">
            <div className="add-course__head">
              SCHEDULES (Add to limit the lesson to only students enrolled in
              these schedules)
            </div>
            {listSchedules &&
              schedules &&
              orderBy(listSchedules, 'id', 'asc').map((item, index) => (
                <ScheduleItem
                  key={index}
                  dispatch={dispatch}
                  item={item}
                  index={index}
                  schedules={schedules}
                  listSchedules={listSchedules}
                  setListSchedules={setListSchedules}
                  newSchedules={newSchedules}
                  formik={formik}
                />
              ))}

            <div
              className="lessons__attachments__add"
              onClick={handleAddNewSchedule}
            >
              <img src={images.admin.plus_normal} alt="" /> ADD A SCHEDULE
            </div>
          </div>
        )}

        <div className="lessons__attachments" style={{ marginTop: 25 }}>
          <div className="lessons__attachments__text">
            Lesson Downloads (Optional)
          </div>
          <div className="lessons__attachments__desc">
            Share or upload supplementary materials for students to refer and
            follow along with your lesson.
          </div>
          <div className="lessons__attachments__warning">
            <img src={images.admin.warning} alt="" /> File sizes limit up to
            10MB. Only JPG, PNG PDF, DOCX and XLS accepted.
          </div>
          {renderAdditionalFile()}

          {selectedSlide && !isTrainer && (
            <>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={refFile}
                onChange={handleChangeFile}
              />
              <div
                className="lessons__attachments__add"
                onClick={handleClickFile}
              >
                <img src={images.admin.plus_normal} alt="" /> ADD A FILE
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default AboutLesson
