import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Modal, Select } from 'antd'
import dayjs from 'dayjs'
import _, { get, isEmpty } from 'lodash'
import { CSVLink } from 'react-csv'

import BtnPrimary from 'component/BtnPrimary'
import {
  hideExportCourseReport,
  hideGlobalLoading,
  showGlobalLoading,
} from '../../actions'
import { useSelector } from 'react-redux'
import {
  mapListCourseToDropDown,
  mapScheduleToDropDown,
  encodedSlideByQuiz,
} from 'utils/helper'
import config from 'src/global-config'
import { getAccessToken } from 'utils/request'
import { showError } from 'utils/notification'
import { getSchedules } from 'container/Admin/actions'
import { makeSelectSchedules } from 'container/Admin/selectors'
import { loadSlides } from 'container/Home/actions'
import { makeSelectSlides } from 'container/Home/selectors'
import { format, msToTime } from 'utils/helper'
import { getUserInfo } from 'utils/request'
import useLoadCourses from '../../../../hooks/useLoadCourses'
import { DOMAIN } from '../../../../routes'
import { makeSelectDomain } from '../../../Home/selectors'

const ExportCourseReport = ({
  dispatch,
  courseSelected,
  showExportCourseReport,
}) => {
  const [csvData, setCsvData] = useState([])
  const exportRef = useRef(null)
  const slides = useSelector(makeSelectSlides())
  const slidesRemoveSection = useMemo(() => {
    const arr = []
    Array.isArray(slides) &&
      slides.forEach((slide) => {
        arr.push(...slide.items)
      })
    return arr
  }, [slides])

  const slideIsQuiz = useMemo(
    () =>
      slidesRemoveSection &&
      slidesRemoveSection?.filter((i) => i?.questions?.length),
    [slidesRemoveSection]
  )

  // const groupedByCategory = useMemo(
  //   () =>
  //     slidesRemoveSection &&
  //     Object.values(_.groupBy(slidesRemoveSection, 'category_id')),
  //   [slidesRemoveSection]
  // )
  const courses = useLoadCourses()
  const schedules = useSelector(makeSelectSchedules())
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const scheduleData = useMemo(
    () => schedules?.find((item) => item?.id === selectedSchedule?.value),
    [selectedSchedule, schedules]
  )
  const [isExportAll, setIsExportAll] = useState(false)
  const course = useMemo(() => {
    if (selectedCourse && courses) {
      return courses.find((course) => course.id === selectedCourse)
    }
  }, [selectedCourse, courses])
  const domain = useSelector(makeSelectDomain())
  const wfaDomain = get(domain, 'domain', '') === DOMAIN.WFA

  const requireSchedule = get(course, 'is_schedule', false)
  const courseName = get(course, 'name', '')
  const scheduleLabel = get(selectedSchedule, 'label', '')
  useEffect(() => {
    if (courseSelected) {
      setSelectedCourse(courseSelected.id)
    }
  }, [courseSelected])

  useEffect(() => {
    if (selectedCourse) {
      dispatch(loadSlides(selectedCourse, false, 0))
      dispatch(getSchedules(selectedCourse, true, null, false, true))
    }
  }, [selectedCourse])

  const handleCancel = () => {
    dispatch(hideExportCourseReport())
  }

  function formatDateDiff(ms) {
    // Convert milliseconds to days, hours, and minutes
    const diffDays = Math.floor(ms / (1000 * 60 * 60 * 24))
    const diffHours = Math.floor(
      (ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const diffMinutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60))

    // Format the units into the desired format (dd:hh:mm)
    if (diffMinutes) {
      const formattedDiff = `${
        diffDays ? diffDays.toString().padStart(2, '0') + 'd' : ''
      } ${
        diffHours ? diffHours.toString().padStart(2, '0') + 'h' : ''
      } ${diffMinutes.toString().padStart(2, '0')}mins`?.trim()

      return formattedDiff
    }
    return ''
  }

  const handleExport = async (isExportAll = false) => {
    if (selectedCourse) {
      setIsExportAll(isExportAll)
      let requestURL = `${
        config.baseUrl
      }/api/lms/courses/channels/${selectedCourse}/partners?schedule_id=${
        !isExportAll ? selectedSchedule?.value || 0 : 0
      }&per_page=1000&ignore_per_page=true&order_by=id%20asc`
      dispatch(showGlobalLoading())
      try {
        const res = await fetch(requestURL, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        const data = await res.json()
        const dataCSV = []

        if (Array.isArray(data.data) && data.data.length === 0) {
          dispatch(hideGlobalLoading())
          return showError('Empty data')
        }

        if (Array.isArray(data.data) && data.data.length > 0) {
          const requests = []
          const requestsUserLogs = []
          const requestsUserPreviews = []
          const requestsUserQuizPassRate = []
          for (let i = 0; i < data.data.length; i++) {
            const partner_id = get(data.data, `[${i}].partner_id`)
            const userId = get(data.data, `[${i}].user.id`)
            const scheduleId = get(data.data, `[${i}].schedule.id`)
            requests.push(getUserInfo(userId, selectedCourse?.website_id))
            requestsUserLogs.push(getUserCourseLog(userId, scheduleId))
            requestsUserPreviews.push(getUserPreviews(partner_id))
            requestsUserQuizPassRate.push(fetchAllAnswerByUser(userId))
          }

          const usersInfo = await Promise.all(requests)
          const userLogs = await Promise.all(requestsUserLogs)
          const userPreviews = await Promise.all(requestsUserPreviews)
          const userQuizPassRate = await Promise.all(requestsUserQuizPassRate)

          for (let i = 0; i < data.data.length; i++) {
            let content = null
            const name =
              get(usersInfo, `[${i}].name`, '') ||
              get(data, `data[${i}].user.partner.name`, '')
            const email = get(data.data, `[${i}].user.login`, '')
            const accessCount = get(userLogs, `[${i}].access_count`, '')
            const courseStart = get(userLogs, `[${i}].course_start`, '')
            const courseDuration = dayjs(
              get(userLogs, `[${i}].course_duration`, '')
            ).isValid()
              ? get(userLogs, `[${i}].course_duration`, '')
              : ''
            const durationTime = dayjs(courseDuration).isAfter(
              dayjs(courseStart)
            )
              ? dayjs(courseDuration).diff(dayjs(courseStart))
              : ''
            const completionDate = get(
              userLogs,
              `[${i}].course_completion_date`,
              ''
            )
            let totalQuestions = 0
            let totalQuizCore = 0

            if (requireSchedule) {
              content = [
                name,
                email,
                courseName,
                scheduleLabel,
                'active',
                accessCount,
                courseStart
                  ? dayjs(courseStart).format('DD/MM/YYYY HH:mm:ss')
                  : '',
                courseDuration
                  ? dayjs(courseDuration).format('DD/MM/YYYY HH:mm:ss')
                  : '',
                formatDateDiff(durationTime),
                completionDate ? 'Completed (100%)' : 'Incomplete',
                completionDate
                  ? dayjs(completionDate).format('DD/MM/YYYY HH:mm:ss')
                  : '',
              ]
            } else {
              content = [
                name,
                email,
                courseName,
                'active',
                accessCount,
                courseStart
                  ? dayjs(courseStart).format('DD/MM/YYYY HH:mm:ss')
                  : '',
                courseDuration
                  ? dayjs(courseDuration).format('DD/MM/YYYY HH:mm:ss')
                  : '',
                formatDateDiff(durationTime),
                completionDate ? 'Completed (100%)' : 'Incomplete',
                completionDate
                  ? dayjs(completionDate).format('DD/MM/YYYY HH:mm:ss')
                  : '',
              ]
            }

            for (let j = 0; j < slidesRemoveSection.length; j++) {
              const previewComplete =
                Array.isArray(userPreviews[i]) &&
                userPreviews[i].find(
                  (item) => item.slide_id === slidesRemoveSection[j].id
                )

              const completionDate = get(previewComplete, 'write_date', '')
                ? dayjs(get(previewComplete, 'write_date', '')).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )
                : ''

              const lessonTimeSpent =
                (previewComplete?.start_date &&
                  previewComplete?.end_date &&
                  msToTime(
                    dayjs(previewComplete?.end_date).diff(
                      dayjs(previewComplete?.start_date)
                    )
                  )) ||
                '0:00:00'

              content = [
                ...content,
                previewComplete ? 'Completed' : 'Incomplete',
                lessonTimeSpent,
                completionDate,
              ]
              const markQuizResult =
                Array.isArray(userQuizPassRate[i]) &&
                userQuizPassRate[i].find(
                  (item) => item.slide_id === slidesRemoveSection[j].id
                )

              const totalPassed = get(markQuizResult, 'answer.total_passed', 0)
              totalQuizCore += +get(
                markQuizResult,
                'answer.percent_quiz_pass',
                0
              )

              if (slidesRemoveSection[j].questions.length > 0) {
                const grade = format(
                  Number(
                    (totalPassed / slidesRemoveSection[j].questions.length) *
                      100
                  )
                )
                if (totalPassed) {
                  totalQuestions++
                }
                if (grade >= 0 && markQuizResult) {
                  content.push(
                    `${completionDate ? 'Completed ' : 'Incomplete'} ${
                      markQuizResult ? `(100%)` : '(0%)'
                    }`
                  )
                } else {
                  content.push('')
                }
              }

              if (j === slidesRemoveSection.length - 1) {
                const averageScore = format(
                  Number(totalQuizCore / totalQuestions)
                )
                if (requireSchedule) {
                  content.splice(9, 0, `${averageScore}%`)
                } else {
                  content.splice(8, 0, `${averageScore}%`)
                }
              }
            }

            dataCSV.push(content)
          }
          let label = []
          if (requireSchedule) {
            label = [
              'Full Name',
              'Email',
              'Course name',
              'Schedule',
              'Account Status',
              'Access Count',
              'First Access',
              'Last Access',
              'Duration',
              'Total Quiz Score',
              'Overall Status',
              'Completion Date',
            ]
          } else {
            label = [
              'Full Name',
              'Email',
              'Course name',
              'Account Status',
              'Access Count',
              'First Access',
              'Last Access',
              'Duration',
              'Total Quiz Score',
              'Overall Status',
              'Completion Date',
            ]
          }

          for (let i = 0; i < slidesRemoveSection.length; i++) {
            label.push(slidesRemoveSection[i].name)
            label.push('Lesson Time Spent')
            label.push('Lesson Completion Date')
            if (slidesRemoveSection[i].questions.length > 0) {
              label.push('Quiz: ' + slidesRemoveSection[i].name)
            }
          }

          dataCSV.unshift(label)

          await setCsvData(dataCSV)
          exportRef.current.link.click()
        }

        handleCancel()
        setIsExportAll(false)
        dispatch(hideGlobalLoading())
      } catch (error) {
        handleCancel()
        setIsExportAll(false)
        dispatch(hideGlobalLoading())
        console.log('error', error)
      }
    }
  }

  const handleExportForWFA = async (isExportAll = false) => {
    if (selectedCourse) {
      setIsExportAll(isExportAll)
      let requestURL = `${
        config.baseUrl
      }/api/lms/courses/channels/${selectedCourse}/partners?schedule_id=${
        !isExportAll ? selectedSchedule?.value || 0 : 0
      }&per_page=1000&ignore_per_page=true&order_by=id%20asc`
      dispatch(showGlobalLoading())
      try {
        const res = await fetch(requestURL, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        const data = await res.json()
        const dataCSV = []

        if (Array.isArray(data.data) && data.data.length === 0) {
          dispatch(hideGlobalLoading())
          return showError('Empty data')
        }

        if (Array.isArray(data.data) && data.data.length > 0) {
          const requests = []
          const requestsUserLogs = []
          const requestsUserPreviews = []
          const requestsUserQuizPassRate = []
          for (let i = 0; i < data.data.length; i++) {
            const partner_id = get(data.data, `[${i}].partner_id`)
            const userId = get(data.data, `[${i}].user.id`)
            const scheduleId = get(data.data, `[${i}].schedule.id`)
            requests.push(getUserInfo(userId, selectedCourse?.website_id))
            requestsUserLogs.push(getUserCourseLog(userId, scheduleId))
            requestsUserPreviews.push(getUserPreviews(partner_id))
            requestsUserQuizPassRate.push(fetchAllAnswerByUser(userId))
          }

          const usersInfo = await Promise.all(requests)
          const userLogs = await Promise.all(requestsUserLogs)
          const userPreviews = await Promise.all(requestsUserPreviews)
          const userQuizPassRate = await Promise.all(requestsUserQuizPassRate)

          for (let i = 0; i < data.data.length; i++) {
            let content = null
            const name =
              get(usersInfo, `[${i}].name`, '') ||
              get(data, `data[${i}].user.partner.name`, '')
            const email = get(data.data, `[${i}].user.login`, '')
            const accessCount = get(userLogs, `[${i}].access_count`, '')
            const courseStart = get(userLogs, `[${i}].course_start`, '')
            const courseDuration = get(userLogs, `[${i}].course_duration`, '')
            const durationTime = dayjs(courseDuration).isAfter(
              dayjs(courseStart)
            )
              ? dayjs(courseDuration).diff(dayjs(courseStart))
              : ''
            const completionDate = get(
              userLogs,
              `[${i}].course_completion_date`,
              ''
            )

            if (requireSchedule) {
              content = [
                name,
                email,
                courseName,
                scheduleLabel,
                'active',
                accessCount,
                courseStart
                  ? dayjs(courseStart).format('DD/MM/YYYY HH:mm:ss')
                  : '',
                courseDuration
                  ? dayjs(courseDuration).format('DD/MM/YYYY HH:mm:ss')
                  : '',
                formatDateDiff(durationTime),
                completionDate ? 'Completed (100%)' : 'Incomplete',
                completionDate
                  ? dayjs(completionDate).format('DD/MM/YYYY HH:mm:ss')
                  : '',
              ]
            } else {
              content = [
                name,
                email,
                courseName,
                'active',
                accessCount,
                courseStart ? dayjs(courseStart).format('DD/MM/YYYY') : '',
                courseDuration
                  ? dayjs(courseDuration).format('DD/MM/YYYY')
                  : '',
                formatDateDiff(durationTime),
                completionDate ? 'Completed (100%)' : 'Incomplete',
                completionDate
                  ? dayjs(completionDate).format('DD/MM/YYYY HH:mm:ss')
                  : '',
              ]
            }

            let quizPassed = []
            for (let j = 0; j < slideIsQuiz.length; j++) {
              //for (let x = 0; x < groupedByCategory[j].length; x++) {
              const previewComplete =
                Array.isArray(userPreviews[i]) &&
                userPreviews[i].find(
                  (item) => item.slide_id === slideIsQuiz[j].id
                )

              const completionDate = get(previewComplete, 'write_date', '')
                ? dayjs(get(previewComplete, 'write_date', '')).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )
                : ''

              const lessonTimeSpent =
                (previewComplete?.start_date &&
                  previewComplete?.end_date &&
                  msToTime(
                    dayjs(previewComplete?.end_date).diff(
                      dayjs(previewComplete?.start_date)
                    )
                  )) ||
                '0:00:00'
              if (!slideIsQuiz[j].show_quiz_only) {
                content = [
                  ...content,
                  previewComplete ? 'Completed' : 'Incomplete',
                  lessonTimeSpent,
                  completionDate,
                ]
              } else {
                content = [...content, lessonTimeSpent, completionDate]
              }

              if (slideIsQuiz[j].questions.length > 0) {
                const markQuizResult =
                  Array.isArray(userQuizPassRate[i]) &&
                  userQuizPassRate[i].find(
                    (item) => item.slide_id === slideIsQuiz[j].id
                  )
                const slide = slideIsQuiz?.find(
                  (item) => item.id === markQuizResult?.slide_id
                )
                const quizPassRate = slide?.quiz_pass_rate

                const totalPassed = get(
                  markQuizResult,
                  'answer.total_passed',
                  0
                )

                const percentGrade =
                  slide &&
                  totalPassed !== 0 &&
                  (totalPassed / slide?.questions?.length) * 100

                const isPassed = percentGrade >= quizPassRate
                quizPassed.push(isPassed)
                const grade = format(
                  Number((totalPassed / slideIsQuiz[j].questions.length) * 100)
                )

                if (grade >= 0 && markQuizResult) {
                  content.push(
                    `${isPassed ? 'Completed ' : 'Incomplete'} ${
                      percentGrade ? `(${Math.round(percentGrade)}%)` : '(0%)'
                    }`
                  )
                } else {
                  content.push('')
                }
              }
              //}
            }
            if (quizPassed.length > 0) {
              if (requireSchedule) {
                content[9] = `${
                  quizPassed.some((item) => !item)
                    ? 'Incomplete'
                    : 'Completed (100%)'
                }`
              } else {
                content[8] = `${
                  quizPassed.some((item) => !item)
                    ? 'Incomplete'
                    : 'Completed (100%)'
                }`
              }
            }

            dataCSV.push(content)
          }
          let label = []
          if (requireSchedule) {
            label = [
              'Full Name',
              'Email',
              'Course name',
              'Schedule',
              'Account Status',
              'Access Count',
              'First Access',
              'Last Access',
              'Duration',
              'Overall Status',
              'Completion Date',
            ]
          } else {
            label = [
              'Full Name',
              'Email',
              'Course name',
              'Account Status',
              'Access Count',
              'First Access',
              'Last Access',
              'Duration',
              'Overall Status',
              'Completion Date',
            ]
          }

          for (let i = 0; i < slideIsQuiz.length; i++) {
            if (!slideIsQuiz[i].show_quiz_only) {
              label.push(slideIsQuiz[i].name)
            }
            label.push('Lesson Time Spent')
            label.push('Lesson Completion Date')
            if (slideIsQuiz[i].questions.length > 0) {
              label.push('Quiz: ' + slideIsQuiz[i].name)
            }
          }

          dataCSV.unshift(label)

          await setCsvData(dataCSV)
          exportRef.current.link.click()
        }

        handleCancel()
        setIsExportAll(false)
        dispatch(hideGlobalLoading())
      } catch (error) {
        handleCancel()
        setIsExportAll(false)
        dispatch(hideGlobalLoading())
        console.log('error', error)
      }
    }
  }

  const getUserCourseLog = async (userId, scheduleId) => {
    try {
      const request = `${
        config.baseUrl
      }/api/users/${userId}/reports/channels?channel_id=${selectedCourse}&schedule_id=${
        scheduleId ? scheduleId : selectedSchedule?.value
      }&order_by=id%20asc`

      const res = await fetch(request, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })

      const data = await res.json()
      return get(data, 'data[0]', {})
    } catch (error) {
      console.log('error', error)
    }
  }

  const getUserPreviews = async (userId) => {
    try {
      const request = `${config.baseUrl}/api/lms/courses/slides/partners?channel_id=${selectedCourse}&partner_id=${userId}&per_page=1000&ignore_per_page=true`

      const res = await fetch(request, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })

      const data = await res.json()
      return get(data, 'data', [])
    } catch (error) {
      console.log('error', error)
    }
  }

  const fetchAllAnswerByUser = async (userId) => {
    const encodedSlideId = encodedSlideByQuiz(slidesRemoveSection)
    try {
      const res = await fetch(
        `${config.baseUrl}/api/users/${userId}/slides/answers?schedule_id=${selectedSchedule?.value}${encodedSlideId}&order_by=id%20asc&per_page=1000&ignore_per_page=true`,
        {
          method: 'GET',
        }
      )
      const data = await res.json()
      return data.data
    } catch (error) {
      console.log('err', error)
    }
  }

  return (
    <Modal
      visible={showExportCourseReport}
      footer={null}
      onCancel={handleCancel}
    >
      <div>
        <div className="ld-modal__warning" style={{ textAlign: 'left' }}>
          Export Course Report
          <BtnPrimary
            name="Export All"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginLeft: 20,
            }}
            handleClick={() =>
              wfaDomain ? handleExportForWFA(true) : handleExport(true)
            }
          />
        </div>
        <div className="sc-modal__label">Courses</div>
        <Select
          className="selectSchedule"
          showSearch
          placeholder="Select a course"
          optionFilterProp="children"
          onChange={(course) => {
            setSelectedCourse(course)
            setSelectedSchedule(null)
          }}
          value={selectedCourse ? selectedCourse : null}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={mapListCourseToDropDown(courses)}
        />

        {requireSchedule && schedules && (
          <>
            <div className="sc-modal__label" style={{ marginTop: 20 }}>
              Schedules
            </div>
            <Select
              className="selectSchedule"
              showSearch
              placeholder="Select a schedule"
              optionFilterProp="children"
              onChange={(_, data) => {
                setSelectedSchedule(data)
              }}
              value={!isEmpty(selectedSchedule) ? selectedSchedule : null}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={mapScheduleToDropDown(schedules, false, false, true)}
            />
          </>
        )}

        {(selectedCourse && selectedSchedule) || !requireSchedule ? (
          <BtnPrimary
            name="Export"
            style={{
              fontWeight: 'bold',
              padding: '8px 10px',
              marginTop: 20,
            }}
            handleClick={() =>
              wfaDomain ? handleExportForWFA(false) : handleExport(false)
            }
          />
        ) : null}

        <CSVLink
          filename={`${
            scheduleData ? scheduleData?.class_code : course?.name
          }_Course Report${isExportAll ? ' (All)' : ''}.csv`}
          data={csvData}
          ref={(r) => (exportRef.current = r)}
        />
      </div>
    </Modal>
  )
}

export default ExportCourseReport
