import React from 'react'
import dayjs from 'dayjs'
import config from 'src/global-config'
import CourseName from '../CourseName'
import { get } from 'lodash'
import { Table } from 'antd'
import { Popconfirm } from 'antd'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { getUserPaymentMethod } from 'container/Home/actions'
import { parseSafe } from 'utils/helper'
import { showSuccess } from 'utils/notification'
import { getInvoiceFLCDomain, updatePaymentMethod } from 'utils/request'
import { showError } from 'utils/notification'
import { getPaymentStatus } from 'utils/request'
import { showGlobalLoading, hideGlobalLoading } from 'container/Modal/actions'
import { getInvoiceUnicornDomain } from 'utils/request'

const obj = {
  paynow: 'PayNow',
  credit: 'SFC Credit Claims',
  company_sponsored: 'Company Sponsored',
  stripe: 'Credit/Debit Card',
  google_pay: 'Google Pay',
  apple_pay: 'Apple Pay',
}

const TablePaymentMethod = ({
  userPaymentMethod,
  listCourseEnrollment,
  user,
  userProfile,
  claim,
  listCourse,
  isUnicornDomain,
  isFLCDomain,
}) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const columns = [
    {
      title: 'Course name',
      width: 250,
      ellipsis: true,
      render: (record) => {
        return <CourseName id={record.channel_id} listCourse={listCourse} />
      },
    },
    {
      title: 'Course ID',
      width: 150,
      render: (record) => {
        return <span>{get(record, 'course_code', '')}</span>
      },
    },
    {
      title: 'Payment date',
      width: 150,
      render: (record) => {
        if (record.payment_method === 'credit') {
          const status = claim?.find((item) => item.id === record.id)
          return get(status, 'claim.SubmissionDate', '')
            ? dayjs(get(status, 'claim.SubmissionDate', '')).format('LL')
            : record?.write_date
            ? dayjs(record?.write_date).format('LL')
            : ''
        }
        return (
          <span>
            {dayjs(record?.create_date || record?.write_date).format('LL')}
          </span>
        )
      },
    },
    {
      title: 'Payment method',
      width: 180,
      render: (record) => {
        return (
          <span>{get(obj, `[${get(record, 'payment_method', '')}]`, '')}</span>
        )
      },
    },
    {
      title: 'Payment ID',
      width: 200,
      render: (record) => {
        return <span>{get(record, 'value', '')}</span>
      },
    },
    {
      title: 'Amount',
      width: 120,
      render: (record) => {
        if (record.payment_method === 'credit') {
          const status = claim?.find((item) => item.id === record.id)
          return get(status, 'claim.amount', '')
        }

        return (
          <span>{get(parseSafe(record.additional_info), 'course_fee', 0)}</span>
        )
      },
    },
    {
      title: 'Payment status',
      width: 150,
      render: (record) => {
        if (record.payment_method === 'credit') {
          const status = claim?.find((item) => item.id === record.id)
          return get(status, 'claim.status', '')
        }

        return (
          <span style={{ textTransform: 'capitalize' }}>
            {get(record, 'status', '')}
          </span>
        )
      },
    },
    {
      title: 'Action',
      width: 150,
      render: (record) => {
        const courseEnrolled =
          Array.isArray(listCourseEnrollment) &&
          listCourseEnrollment.find((course) => course.id === record.channel_id)

        return (
          <div>
            <>
              <span
                style={{
                  marginRight: 5,
                }}
                className="view-details"
                onClick={() =>
                  handleUpdateInvoice(
                    courseEnrolled,
                    record,
                    userPaymentMethod,
                    claim
                  )
                }
              >
                Update Invoice
              </span>
              {record && record.status !== 'cancelled' ? (
                <span style={{ color: '#000', marginRight: 5 }}> / </span>
              ) : (
                ''
              )}
            </>
            {record && record.status !== 'cancelled' ? (
              <Popconfirm
                placement="topLeft"
                title="Do you want to reset this claim?"
                onConfirm={() => {
                  handleReset(record)
                }}
                okText="Yes"
                cancelText="No"
              >
                <div className="red underline pointer">Cancel Payment</div>
              </Popconfirm>
            ) : null}
          </div>
        )
      },
    },
  ]

  const getInvoicePdf = async ({
    studentName,
    invoiceNo,
    schedule,
    courseFee,
    course,
    course_code,
    create_date,
    user_payment_method,
    claim,
  }) => {
    try {
      const res = await fetch(`${config.api}/pdf`, {
        body: JSON.stringify({
          trainee_name: studentName,
          invoice_no: invoiceNo,
          schedule,
          student_name: studentName,
          course_start_date: dayjs(get(schedule, 'start_date')).format(
            'YYYY-MM-DD'
          ),
          course_end_date: dayjs(get(schedule, 'end_date')).format(
            'YYYY-MM-DD'
          ),
          course_fee: courseFee,
          course: course,
          course_code,
          create_date,
          user_payment_method,
          claim,
        }),
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
      })
      const data = await res.json()
      if (data.pdf) {
        return data
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleUpdateInvoice = async (
    record,
    paymentMethod,
    userPaymentMethod,
    claim
  ) => {
    const additionalData = parseSafe(get(paymentMethod, 'additional_info', ''))
    const schedule = get(record, 'partners[0].schedule', null)
    const additionalDataInfo = parseSafe(
      get(userProfile, 'additional_info', '')
    )
    try {
      const invoiceNo = get(additionalData, 'invoice_no', '')
      const studentName = get(userProfile, 'name', '')
      const companyName =
        get(additionalDataInfo, 'company', '') ||
        get(additionalData, 'company_name', '')
      const companyBilling = get(additionalData, 'company_billing_address')
      const courseFee = get(additionalData, 'course_fee', '')
      const billingAddress = get(additionalDataInfo, 'user_billing_address')
      const dateEnrolled = dayjs(
        get(record, 'partners[0].create_date', '')
      ).format('DD/MM/YYYY')

      let invoiceFlc = ''
      if (isFLCDomain) {
        invoiceFlc = await await getInvoiceFLCDomain(
          invoiceNo,
          studentName,
          schedule,
          courseFee,
          companyName,
          record,
          dateEnrolled,
          billingAddress,
          companyBilling
        )
      } else if (isUnicornDomain) {
        const invoiceSequence =
          record?.id === 531 &&
          (await getInvoiceNumber({
            domain: 'unicorn',
            id: record?.id,
          }))
        invoiceFlc = await getInvoicePdf({
          studentName,
          invoiceNo:
            record?.id === 531
              ? `WDS-${dayjs().format('YYYYMMDD')}-${invoiceSequence
                  .toString()
                  .padStart(4, '0')}`
              : `${dayjs(get(record, 'partners[0].create_date', '')).format(
                  'YYYYMMDD'
                )}-${
                  get(paymentMethod, 'course_code')
                    ? get(paymentMethod, 'course_code', '')
                    : get(record, 'id', '')
                }-${get(paymentMethod, 'user_id')}`,
          schedule,
          courseFee,
          course: record,
          course_code: get(paymentMethod, 'course_code', ''),
          create_date: get(record, 'partners[0].create_date', ''),
          user_payment_method: userPaymentMethod,
          claim,
        })
      }

      if (paymentMethod && invoiceFlc) {
        const body = { ...paymentMethod }
        if (schedule?.id) {
          body.schedule_id = schedule?.id
        }
        if (body.payment_method === 'credit') {
          body.attachment = invoiceFlc.pdf
        } else if (body.payment_method === 'paynow') {
          body.invoice_url = invoiceFlc.pdf
          delete body.attachment
        } else {
          body.invoice_url = invoiceFlc.pdf
        }
        body.attachment_size = invoiceFlc?.fileSize
        const updatedPayment = await updatePaymentMethod(body)
        if (!!updatedPayment) {
          showSuccess('Updated invoice!')
        }
      }
    } catch (error) {}
  }

  const getInvoiceNumber = async (values) => {
    const requestURL = `${config.baseUrl}/api/invoices/numbers`
    try {
      const res = await fetch(requestURL, {
        method: 'POST',
        body: JSON.stringify({
          domain: values.domain,
          channel_id: values?.id,
        }),
      })
      const data = await res.json()
      if (data?.data?.value) {
        return data?.data?.value
      }
    } catch (err) {
      return null
    }
  }

  const cancelSFCPaymentMethod = async (record) => {
    try {
      const requestUrl = `${config.baseUrl}/api/payments/sfc/claims`
      const res = await fetch(requestUrl, {
        method: 'DELETE',
        body: JSON.stringify({
          claim_id: get(record, 'value', ''),
          channel_id: get(record, 'channel_id', ''),
          nric: get(record, 'nric', ''),
          claimCancelCode: '51',
        }),
      })
      const data = await res.json()
      return (
        get(data, 'data.status') === 200 &&
        get(data, 'data.claim.status', '') === 'Cancelled'
      )
    } catch (error) {
      return false
    }
  }

  const handleReset = async (record) => {
    dispatch(showGlobalLoading())
    if (!record) {
      return showError('Empty payment method')
    }

    if (record.payment_method === 'credit') {
      const res = await cancelSFCPaymentMethod(record)
      const data = await getPaymentStatus(record)
      const paymentStatus = get(data, 'claim.status', '')

      if (res || paymentStatus === 'Cancelled') {
        delete record.attachment
        record.attachment = record.attachment_data
        await updatePaymentMethod({
          ...record,
          status: 'cancelled',
        })
      }
    } else {
      delete record.attachment
      record.attachment = record.attachment_data
      await updatePaymentMethod({
        ...record,
        status: 'cancelled',
      })
    }

    dispatch(getUserPaymentMethod({ userId: user }))
    dispatch(hideGlobalLoading())
  }

  return (
    <div className="table-schedule__wrap">
      <Table
        columns={columns}
        dataSource={userPaymentMethod}
        rowClassName="table-row"
        pagination={{ defaultPageSize: 8 }}
        rowKey={(record) => record.id}
        scroll={isMobile ? { x: 1000, y: 400 } : null}
      />
    </div>
  )
}

export default TablePaymentMethod
