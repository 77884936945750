import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { get } from 'lodash'
import { Row, Col } from 'antd'
import { getImageAttachment } from 'utils/helper'
import Invoice from '../../../Admin/components/Reports/Invoice/index'
import { useSelector } from 'react-redux'
import { makeSelectDomain } from 'container/Home/selectors'
import { DOMAIN } from '../../../../routes/index'
import { parseSafe } from 'utils/helper'
import { makeSelectUserProfile } from '../../../Admin/selectors'
import { getPaymentStatus } from 'utils/request'
import { saveAs } from 'file-saver'
import { isMobile, isChrome } from 'react-device-detect'

const DetailPayment = ({ course, data, partner }) => {
  const userProfile = useSelector(makeSelectUserProfile())

  const domain = useSelector(makeSelectDomain())
  const [paymentStatus, setPaymentStatus] = useState()
  const isFLCDomain = get(domain, 'domain', '') === DOMAIN.FLC
  const isPortalDomain = get(domain, 'domain', '') === DOMAIN.PORTAL
  const isUnicornDomain = get(domain, 'domain', '') === DOMAIN.UNICORN_DOMAIN

  useEffect(() => {
    const getSFCDetailsData = async () => {
      if (!!data && data?.payment_method === 'credit') {
        const status = await getPaymentStatus(data)
        setPaymentStatus(status)
      }
    }
    getSFCDetailsData()
  }, [data])

  function b64toFile(b64Data, filename) {
    var sliceSize = 512
    var byteCharacters = atob(b64Data)
    var byteArrays = []

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize)
      var byteNumbers = new Array(slice.length)

      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      var byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    var file = new File(byteArrays, filename, { type: 'application/pdf' })
    return file
  }

  const handleDownloadInvoice = (file, fileName) => {
    const blob = b64toFile(file, fileName)
    if (isMobile) {
      if (isChrome) {
        saveAs(blob, fileName)
      } else {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }
    } else {
      saveAs(blob, fileName)
    }
  }

  const renderPaymentDetail = () => {
    const method = get(data, 'payment_method', '')
    const attachment = get(data, 'attachment')
    const url = getImageAttachment(attachment)
    const courseCode =
      get(data, 'course_code', '') || get(course, 'course_code', '')

    const additionData = parseSafe(get(data, 'additional_info', ''))
    const companyFullName = get(additionData, 'company_name', '')
    const contactName = get(additionData, 'contact_name', '')
    const contactMobile = get(additionData, 'contact_mobile', '')
    const contactEmail = get(additionData, 'contact_email', '')

    const renderInvoice = () => {
      const invoiceUrl = get(data, 'invoice_url', '')
      if ((isFLCDomain || isPortalDomain) && invoiceUrl) {
        const additionalData = parseSafe(data?.additional_info)
        const invoiceNo = get(additionalData, 'invoice_no', '')
        const sequenceNum = get(additionalData, 'sequence_num', '')
        const classCode = get(additionalData, 'class_code', '')
        const dateEnrol = dayjs(get(data, 'write_date', '')).format('YYMMDD')
        const dateEnrol2 = dayjs(get(data, 'write_date', '')).format('MMDD')
        const fileName = `${get(userProfile, 'name', '')
          .trim()
          .replace(
            /\s/g,
            '_'
          )}_${dateEnrol}_${sequenceNum}-${dateEnrol2}-${classCode}.pdf`

        const fileName2 = `${get(userProfile, 'name', '')
          .trim()
          .replace(/\s/g, '_')}_${dateEnrol}_${invoiceNo || ''}.pdf`
        return (
          <a
            className="credit__highlight"
            onClick={() =>
              handleDownloadInvoice(
                invoiceUrl,
                classCode ? fileName : fileName2
              )
            }
            target="_blank"
          >
            Download Invoice
          </a>
        )
      }

      if (isUnicornDomain && invoiceUrl) {
        const dateEnrollment = dayjs(get(data, 'write_date', ''))
        const invoiceNo = `${dayjs(dateEnrollment).format('YYYYMMDD')}-${
          get(course, 'course_code')
            ? get(course, 'course_code', '')
            : get(course, 'id', '')
        }-${get(partner, 'partner.id', '')}`

        const invoiceCourse531 = `WDS_INV_${dayjs(dateEnrollment).format(
          'YYYYMMDD'
        )}_${get(userProfile, 'name', '').trim().replace(/\s/g, '_')}`
        return (
          <a
            className="credit__highlight"
            onClick={() =>
              handleDownloadInvoice(
                invoiceUrl,
                course?.id === 531 ? invoiceCourse531 : `invoice_${invoiceNo}`
              )
            }
          >
            Download Invoice
          </a>
        )
      }

      return (
        course &&
        get(data, 'payment_method', '') && (
          <Col className="profile__row">
            <Col md={18}>
              <Invoice course={course} currentUser={partner} isAdmin />
            </Col>
          </Col>
        )
      )
    }

    if (method === 'company_sponsored') {
      return (
        <>
          <Row className="profile__row" gutter={10}>
            <Col md={6} className="profile__name">
              Payment Method:
            </Col>
            <Col md={18}>Company Sponsored</Col>
          </Row>
          <Row className="profile__row" gutter={10}>
            <Col md={6} className="profile__name">
              Full name of company:
            </Col>
            <Col md={18}>{companyFullName}</Col>
          </Row>

          <Row className="profile__row">
            <Col md={24} className="profile__name">
              Company contact person:
            </Col>
          </Row>
          <Row className="profile__row" gutter={10}>
            <Col md={6} className="profile__name">
              Name
            </Col>
            <Col md={18}>{contactName}</Col>
          </Row>
          <Row className="profile__row" gutter={10}>
            <Col md={6} className="profile__name">
              Tel
            </Col>
            <Col md={18}>{contactMobile}</Col>
          </Row>
          <Row className="profile__row" gutter={10}>
            <Col md={6} className="profile__name">
              Email
            </Col>
            <Col md={18}>{contactEmail}</Col>
          </Row>
          {renderInvoice()}
        </>
      )
    }
    if (method === 'credit') {
      const additionalData = parseSafe(data?.additional_info)
      const invoiceNo = get(additionalData, 'invoice_no', '')
      const sequenceNum = get(additionalData, 'sequence_num', '')
      const classCode = get(additionalData, 'class_code', '')
      const dateEnrol = dayjs(get(data, 'write_date', '')).format('YYMMDD')
      const dateEnrol2 = dayjs(get(data, 'write_date', '')).format('MMDD')
      const fileName = `${get(userProfile, 'name', '')
        .trim()
        .replace(
          /\s/g,
          '_'
        )}_${dateEnrol}_${sequenceNum}-${dateEnrol2}-${classCode}.pdf`

      const invoiceUrl = get(data, 'attachment_data', '')
      const fileName2 = `${get(userProfile, 'name', '')
        .trim()
        .replace(/\s/g, '_')}_${dateEnrol}_${invoiceNo || ''}.pdf`
      return (
        <>
          <Row className="profile__row" gutter={10}>
            <Col md={6} className="profile__name">
              Payment Method:
            </Col>
            <Col md={18}>SkillsFuture Credit</Col>
          </Row>
          <Row className="profile__row" gutter={10}>
            <Col md={6} className="profile__name">
              Course ID:
            </Col>
            <Col md={18}>{courseCode}</Col>
          </Row>
          <Row className="profile__row" gutter={10}>
            <Col md={6} className="profile__name">
              SFC Claim ID:
            </Col>
            <Col md={18}>{get(data, 'value', '')}</Col>
          </Row>
          {paymentStatus && (
            <>
              <Row className="profile__row" gutter={10}>
                <Col md={6} className="profile__name">
                  Claim Status:
                </Col>
                <Col md={18}>{get(paymentStatus, 'claim.status', '')}</Col>
              </Row>
              <Row className="profile__row" gutter={10}>
                <Col md={6} className="profile__name">
                  Invoice submission status
                </Col>
                <Col md={18}>
                  {get(paymentStatus, 'attachmentUploadStatus', '')}
                </Col>
              </Row>
              <Row className="profile__row" gutter={10}>
                <Col md={6} className="profile__name">
                  Claim amount:
                </Col>
                <Col md={18}>{get(paymentStatus, 'claim.amount', '')}</Col>
              </Row>
            </>
          )}
          {url ? (
            isFLCDomain || isPortalDomain ? (
              <a
                className="credit__highlight"
                onClick={() =>
                  handleDownloadInvoice(
                    invoiceUrl,
                    classCode ? fileName : fileName2
                  )
                }
              >
                Download Invoice
              </a>
            ) : (
              <a className="credit__highlight" href={url + '?download=true'}>
                Download Invoice
              </a>
            )
          ) : (
            get(data, 'payment_method', '') &&
            course && (
              <Col className="profile__row">
                <Col md={14}>
                  <Invoice course={course} currentUser={partner} isAdmin />
                </Col>
              </Col>
            )
          )}
        </>
      )
    }

    if (method === 'paynow') {
      return (
        <>
          <Row className="profile__row" gutter={10}>
            <Col md={6} className="profile__name">
              Payment Method:
            </Col>
            <Col md={18}>Paynow</Col>
          </Row>
          <Row className="profile__row" gutter={10}>
            <Col md={6} className="profile__name">
              Payment Reference No.:
            </Col>
            <Col md={18}>{get(data, 'value', '')}</Col>
          </Row>

          {data.attachment && (
            <Row className="profile__row" gutter={10}>
              <Col md={6} className="profile__name">
                Bank transfer screenshot:
              </Col>
              <Col md={18}>
                <div className="wrapper-img">
                  <img src={getImageAttachment(data.attachment)} alt="" />
                </div>
              </Col>
            </Row>
          )}
          {renderInvoice()}
        </>
      )
    }
  }
  return <div className="mgB-30">{renderPaymentDetail()}</div>
}

export default DetailPayment
