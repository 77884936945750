import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { LoginSuccess } from 'container/Auth/actions'
import { useMediaQuery } from 'react-responsive'
import images from '../../../../../Themes/Images'
import { DOMAIN } from 'src/routes'
import useComponentVisible from 'utils/hooks'
import { useClickOutside } from 'utils/hooks'
import { Popover } from 'antd'
import { setAccessToken } from 'utils/request'

const NavBarWrapper = styled.div`
  background-color: #061b37;

  @media (max-width: 767px) {
    padding: 0 20px;
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
  }

  .school__name a,
  .school__name span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 0;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  div.search_wrap {
    display: flex;
  }
  h1 {
    color: #ffffff;
    font-weight: 600;
  }

  .logout {
    text-decoration: underline;
  }
  .nav-menu a,
  span {
    cursor: pointer;
    padding-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    transition: all 0.2s ease;
  }
  .nav-menu__item {
    border-top: 1px solid #fff;
    padding-bottom: 5px;
    a {
      display: block;
    }
  }
`

const Wrap = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;

  justify-content: space-between;
  margin: 0 auto;
  max-width: 990px;
  @media (min-width: 1920px) {
    max-width: 1320px;
  }
`

const NavTabWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  & > div,
  & > a {
    cursor: pointer;

    margin-right: 30px;
    @media (max-width: 767px) {
      padding-right: 10px;
    }

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;

    color: ${(props) => (props.isFe ? '#5a6686' : '#fff;')};
  }
`

const NewNavBar = ({ domain }) => {
  const isSingaporeSkillsAcademies =
    domain && domain.domain === DOMAIN.SINGAPORESKILLS
  const history = useHistory()
  const [showMenu, setShowMenu] = useState(false)
  const dispatch = useDispatch()
  const currentUser = useSelector(makeSelectCurrentUser())
  const isAdmin = get(currentUser, 'is_admin', false)

  const isMobile = useMediaQuery({ maxWidth: 767 })

  const handleLogin = () => {
    history.push('/auth?login=true&student=true')
  }

  const handleLogOut = () => {
    localStorage.removeItem('submittedAnswer')
    localStorage.removeItem('clientID')
    localStorage.removeItem('clientName')
    localStorage.removeItem('countdownDeadline')
    localStorage.removeItem('currentSlideId')
    localStorage.removeItem('notPassedQuiz')
    localStorage.removeItem('percentComplete')
    dispatch(LoginSuccess(null))
    setAccessToken('')
    setTimeout(() => {
      window.location.reload()
    }, 0)
  }

  const handleAdmin = () => {
    history.push('/admin')
  }

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  const { ref: refMenu } = useClickOutside(() => setShowMenu(false))
  const renderNavTab = () => {
    return isMobile ? (
      <div onClick={() => setShowMenu(!showMenu)}>
        <img
          src={images.header.bars}
          alt="Menu"
          className="menu__icon"
          style={{ width: 16 }}
        />
      </div>
    ) : (
      <NavTabWrapper>
        {isSingaporeSkillsAcademies && (
          <>
            <a
              href="https://www.facebook.com/Singapore-Skills-Academies-105882221871358"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{ width: 30, paddingBottom: 4 }}
                src={images.f_logo}
                alt="Facebook Icon"
              />
            </a>
            <Link to="/about">About Us</Link>
          </>
        )}
        <Link to="/">Courses</Link>
        {currentUser ? (
          <Popover
            placement="bottomRight"
            overlayClassName="popup_confirm_navbar"
            content={
              <div className="popup-cta">
                {isAdmin && (
                  <div className="popup-cta__wrap" onClick={handleAdmin}>
                    <div className="popup-cta__text">Admin</div>
                  </div>
                )}
                <div className="popup-cta__wrap" onClick={handleLogOut}>
                  <div className="popup-cta__text">Logout</div>
                </div>
              </div>
            }
            trigger="click"
            visible={isComponentVisible}
          >
            <div
              className="logged"
              style={{ textDecoration: 'underline' }}
              ref={ref}
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            >
              <span>
                {get(currentUser, 'name', '') ||
                  get(currentUser, 'partner.name', '')}
              </span>
            </div>
          </Popover>
        ) : (
          <div onClick={handleLogin}>Log in</div>
        )}
      </NavTabWrapper>
    )
  }
  const renderLogo = () => {
    return <Link to="/">{renderSchoolName()}</Link>
  }

  const renderSchoolName = () => {
    if (domain.id === 20) {
      return (
        <>
          <span style={{ color: '#ec1c34' }}>SINGAPORE</span>&nbsp;SKILLS
          ACADEMIES
        </>
      )
    }
    return domain && domain.name
  }

  return (
    <NavBarWrapper>
      <Wrap>
        <div className="school__name">{renderLogo()}</div>
        <div>{renderNavTab()}</div>
      </Wrap>
      {isMobile && showMenu && (
        <div className="nav-menu" ref={refMenu}>
          {isSingaporeSkillsAcademies && (
            <>
              <a
                href="https://www.facebook.com/Singapore-Skills-Academies-105882221871358"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ width: 20 }}
                  src={images.f_logo}
                  alt="Facebook Icon"
                />
              </a>
            </>
          )}
          {isSingaporeSkillsAcademies && (
            <div className="nav-menu__item">
              <Link to="/about">About Us</Link>
            </div>
          )}

          <div className="nav-menu__item">
            <Link to="/">Courses</Link>
          </div>
          <div className="nav-menu__item">
            {currentUser ? (
              <div className="logged" onClick={handleLogOut}>
                <span>
                  Hi{' '}
                  {get(currentUser, 'name', '') ||
                    get(currentUser, 'partner.name', '')}
                  ,&nbsp;
                </span>
                <span className="logout">log out</span>
              </div>
            ) : (
              <Link to={`/auth?login=true&student=true`}>Login</Link>
            )}
          </div>
        </div>
      )}
    </NavBarWrapper>
  )
}

export default NewNavBar
