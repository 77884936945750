import React, { useEffect, useMemo, useState } from 'react'
import { get } from 'lodash'

import { Popover } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'

import images from 'Themes/Images'
import Menu from '../Menu'
import NavbarMobile from '../NavbarMobile'
import useComponentVisible from 'utils/hooks'
import { Logout, setAccessToken } from 'container/Auth/actions'
import { makeSelectCurrentUser } from 'container/Auth/selectos'
import { setRef } from 'container/Home/actions'
import './styles.scss'
import { parse } from 'query-string'
import { makeSelectClient } from 'container/Checkout/selectors'
import { isMainAdmin } from '../../utils/helper'
import { DOMAIN } from '../../routes'

const Header = ({ domain }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentUser = useSelector(makeSelectCurrentUser())
  const client = useSelector(makeSelectClient())
  const isAdmin = get(currentUser, 'is_admin', false)

  const isSuperAdmin =
    domain.domain === 'https://admin.hypeschools.com/' ||
    domain.domain === 'https://admin-staging.hypeschools.com/'

  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { pathname, search } = useLocation()
  const { preview } = parse(search)
  const clientName = localStorage.getItem('clientName') || ''
  const {
    ref: userRef,
    isComponentVisible: isMenuUserVisible,
    setIsComponentVisible: setIsMenuUserVisible,
  } = useComponentVisible(false)

  const isAscendo =
    domain?.domain === DOMAIN.ASCENDO_2 || domain?.domain === DOMAIN.ASCENDO

  const isAdminMain = isMainAdmin(currentUser)

  const [hideNavBar, setHideNavBar] = useState(false)
  const [isOnAdmin, setIsOnAdmin] = useState(false)
  const userClient = useMemo(() => currentUser?.user_clients, [currentUser])

  useEffect(() => {
    if (pathname === '/auth' || pathname === '/web/reset_password') {
      setHideNavBar(true)
    } else {
      setHideNavBar(false)
    }
    if (pathname.match(/admin/)) {
      setIsOnAdmin(true)
    } else {
      setIsOnAdmin(false)
    }
  }, [pathname])

  if (hideNavBar) {
    return null
  }
  const isAdminPage = pathname.match(/admin/)

  const handleLogOut = () => {
    localStorage.removeItem('submittedAnswer')
    localStorage.removeItem('clientID')
    localStorage.removeItem('clientName')
    localStorage.removeItem('countdownDeadline')
    localStorage.removeItem('currentSlideId')
    localStorage.removeItem('notPassedQuiz')
    localStorage.removeItem('percentComplete')
    dispatch(setRef(null))
    setAccessToken('')
    dispatch(Logout())
    dispatch(setAccessToken(''))
    setTimeout(() => {
      window.location.reload()
    }, 0)
  }

  const renderSchoolName = () => {
    return (
      <Link to={!isAdminPage ? '/' : '/admin?page=1&perpage=15'}>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: 18,
            color: '#fff',
            whiteSpace: 'nowrap',
          }}
        >
          {domain.id === 20 ? (
            <span>
              <span style={{ color: '#ec1c34' }}>SINGAPORE</span> SKILLS
              ACADEMIES
            </span>
          ) : (
            clientName ? clientName : domain && domain.name
          )}
        </div>
      </Link>
    )
  }

  const renderClientName = () => (
    <Link to={`/${client?.slug}`}>
      <div
        style={{
          fontWeight: 'bold',
          fontSize: 18,
          color: '#fff',
          whiteSpace: 'nowrap'
        }}
      >
        {client?.name}
      </div>
    </Link>
  )

  const handleAdmin = () => history.push('/admin')

  return (
    <>
      {!isMobile && (
        <div className="navbar">
          <div className="navbar__menu" style={{ fontSize: 12 }}>
            <Menu
              isOnAdmin={isOnAdmin}
              isSuperAdmin={isSuperAdmin}
              preview={preview}
            />
          </div>

          {userClient?.length
            ? client && renderClientName()
            : domain && renderSchoolName()}

          {currentUser && (
            <div className="navbar__account" style={{ fontSize: 12 }}>
              <Popover
                placement="bottomRight"
                overlayClassName="popup_confirm popup_confirm-2"
                content={
                  <div className="popup-cta">
                    {(isAscendo && isAdminMain) || (!isAscendo && isOnAdmin) ? (
                      <div
                        className="popup-cta__wrap"
                        onClick={() => {
                          history.push('/admin/settings?route=branding')
                        }}
                      >
                        <div className="popup-cta__text">Settings</div>
                      </div>
                    ) : null}
                    {isAdmin && (
                      <div className="popup-cta__wrap" onClick={handleAdmin}>
                        <div className="popup-cta__text">Admin</div>
                      </div>
                    )}
                    <div className="popup-cta__wrap" onClick={handleLogOut}>
                      <div className="popup-cta__text">Logout</div>
                    </div>
                  </div>
                }
                trigger="click"
                visible={isMenuUserVisible}
              >
                <div
                  ref={userRef}
                  className="navbar__account__fullname"
                  onClick={() => setIsMenuUserVisible(!isMenuUserVisible)}
                >
                  {get(currentUser, 'name', '') ||
                    get(currentUser, 'partner.name', '')}
                  <div style={{ marginLeft: 5 }}>
                    <img src={images.header.caret_down_nav} alt="" />
                  </div>
                </div>
              </Popover>
            </div>
          )}

          {!currentUser && (
            <div className="auth-wrap">
              <div className="auth__login">
                <Link to="/auth?login=true&student=true">LOG IN</Link>
              </div>
              <div className="auth__signup">
                <Link to="/auth?signup=true">
                  <button className="quaternary-btn">REGISTER</button>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}

      {isMobile && (
        <NavbarMobile
          domain={domain}
          isAdminPage={isAdminPage}
          currentUser={currentUser}
          handleLogOut={handleLogOut}
          isOnAdmin={isOnAdmin}
          isSuperAdmin={isSuperAdmin}
          history={history}
          isMenuUserVisible={isMenuUserVisible}
          userRef={userRef}
          setIsMenuUserVisible={setIsMenuUserVisible}
          isAdmin={isAdmin}
          handleAdmin={handleAdmin}
          preview={preview}
          isAdminMain={isAdminMain}
          isAscendo={isAscendo}
        />
      )}
    </>
  )
}

export default Header
